<template>
	<!-- star -->

	<div class="homepage_wrap">
		<!-- banner图和导航栏 -->
		<div class="banner_nav">
			<!-- banner -->
			<div class="banner_nav_bg">
				<img src="../assets/img/bg3.png" alt="" />
			</div>
			<!-- 导航栏 -->
			<div class="navWrap">
				<div class="navWrap_center">
					<div class="navWrap_center_img">
						<img src="../assets/img/logo.png" alt="" />
					</div>

					<!-- <div class="dropdown">
						<button class="dropbtn" @click="funRouter">集团产业</button>
						<div class="dropdown-content">
							<router-link to="/keji">科技</router-link>
							<router-link to="/zhijiao">职教</router-link>
							<router-link to="/youjiao">幼教</router-link>
							<router-link to="/dianshang">电商</router-link>
							<router-link to="/jinrong">金融</router-link>
						</div>
					</div> -->

					<ul class="navWrap_center_ul">
						<!-- <li
							v-for="(item, index) in navList"
							:key="index"
							@click="funyuming(item.url)"
						> -->
						<li @click="funyuming('/')">
							首页
						</li>
						<li @click="funyuming('/')">
							<a href="#form1">公司简介</a>
						</li>
						<li @click="funyuming('/')">
							<a href="#form2">业务范围</a>
						</li>
						<li @click="funyuming('/')">
							<a href="#form3">服务方案</a>
						</li>
						<li @click="funyuming('/jituangaikuo')">
							收费标准
						</li>
						<li @click="funyuming('/lianxi')">
							联系我们
						</li>
						<li @click="funyuming('/zhaopinxinxi0')">
							招聘信息
						</li>
					</ul>
				</div>
			</div>
			<!-- title -->
			<div class="banner_title_wrap">
				<div class="banner_title_center">
					<p style="font-size: 70px">{{ title }}</p>
					<div><span style="font-size: 30px;">益全<span>-</span>为了我们更好的安全</span></div>
					<div style="margin-top: 60px;">
						<p style="line-height: 40px;">服务范围：安全评价、安全生产标准化咨询辅导、应急预案编制、安全设施<p>
						<p style="line-height: 40px;">设计、双重预防机制、安全管家服务、安全培训、职业卫生技术咨询服务</p>
					</div>
				</div>
			</div>
		</div>
		<!-- 公司简介 -->
		<div class="news" id="form1">
			<div class="news_cont">
				<!-- left -->
				<div class="news_cont_left">
					<h1>公司简介</h1>
					<div class="news_bg">
						<div class="news_cont_left_img">
							<img src="../assets/img/news_img1.png" alt="" />
						</div>
						<div class="news_cont_left_text">
							<p>
								&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;安全生产是企业生存和发展的基本保证，“安全第一、预防为主，综合治理”是我国安全生产管理的方针，也是企业必须遵循的一条准则。
							</p>
							<p>
								&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;为更好服务企业，发挥安全第三方公司专业技术服务支撑，遵守安全生产专业服务机构行业自律工作机制，促进我省安全生产技术服务行业健康持续发展，维护行业整体形象，提高职业素养，规范执业行为、应当弘扬行业正气、公平竞争，杜绝无序、恶意和不正当的竞争。坚持以服务为主，合理收费，保障技术服务品质。真正做到专业赋能，专业
							</p>
						</div>
					</div>
				</div>
				<!-- right -->
				<div class="news_cont_right">
					<div class="news_cont_right_ul">
					</div>
					<div class="news_cont_right_text">
						<p>
							授企，通过技术服务提升企业安全管理水平及素养。充分发挥中介服务在安全生产中的桥梁和纽带作用。
						</p>
						<p>
							&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;安徽益全安全科技有限公司(简称：益全科技)成立于2022年11月，注册资金500.00万元，注册及办公地点为合肥市瑶海区徽都汇嘉中心7楼703室(益全科技)。
						</p>
						<p>
							&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;益全科技，以“精益求精、全心全意”为服务宗旨，为各生产经营单位和政府提供安全生产技术咨询及管理服务。秉承着技术、质量做到精益求精，管理、服务做到全心全意。
						</p>
						<P>
							&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;我司承诺：合法合规开展技术工作、全心全意为企业做好安全管理服务，坚持“精益求精、全心全意”的服务宗旨，帮助企业提高安全管理水平，实现企业安全稳定地发展。
						</P>
						<p>
							&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;我公司主要经营范围如下：
						</p>
						<p>
								&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1.安全评价(含专项安全评价、易制毒、易制爆、剧毒化学品安全评价)、安全风险评
							估、安全设计诊断、安全设施“三同时”(安全预评价或安全生产条件和设施综合分析报
							告、安全设施设计、安全设施竣工验收)。
						</p>
						<p>
								&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2.安全生产标准化、应急预案(生产安全及突发环境事件)、安全管家服务(含安全教
							育培训)、企事业单位及政府第三方安全检查、安全风险分级和隐患排查治理双重预防
							工作机制辅导服务(双重预防机制)等安全技术咨询、安全管理服务。
						</p>
						<p>
								&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3.职业卫生评价及检测咨询服务、职业健康管理技术服务，职业病危害预评价、职业
							病防护设施设计、职业病危害控制效果评价、职业病危害现状评价、职业病危害因素定
							期检测及系统申报、职业卫生风险分级、职业卫生培训。
						</p>					
						<!-- <div style="margin-top: 15px;text-align: center;">
							<img src="../assets/img/营业执照.png" alt="" style="width: 300px;height: 200px;"/>
						</div> -->
					</div>
				</div>
			</div>
		</div>

		<!-- 业务范围 -->
		<div class="GroupIndustry" id="form2">
			<div class="GroupIndustry_cont">
				<!-- 左 -->
				<div class="GroupIndustry_cont_left">
					<!-- title -->
					<div class="GroupIndustry_cont_left_title">
						<p>业务范围</p>
					</div>
					<!-- img -->
					<div class="GroupIndustry_cont_left_img_f">
						<div
							v-for="(item, index) in jituanList"
							:key="index"
							class="GroupIndustry_cont_left_img"
						>
							<img :src="item.jituanimg" />
							<img
								:src="item.jituanimgyinying"
								class="GroupIndustry_cont_left_img2"
							/>
							<div class="GroupIndustry_cont_left_text">
								<span>{{ item.jituanspan }}</span>
								<p>{{ item.jituanp }}</p>
							</div>
						</div>
					</div>
					<!-- right -->
				</div>
				<!-- right -->
				<div class="GroupIndustry_cont_right">
					<!-- title -->
					<!-- <div class="GroupIndustry_cont_right_title">更多></div> -->
					<div class="GroupIndustry_cont_right_img">
						<img src="../assets/img/zhiyewushenjishuzixunfuwu.png" alt="" />
						<img
							class="GroupIndustry_cont_right_img2"
							src="../assets/img/jituanyinying.png"
							alt=""
						/>
					</div>
					<div class="GroupIndustry_cont_right_text">
						<p>职业卫生技术咨询服务</p>
						<span>职业病危害预评价 | 职业病防护设施设计 | 职业病危害控制效果评价 | 职业病危害现状评价 | 职业病危害因素定期检测 | 职业病危害风险分级 | 职业病危害系统申报、专项治理评估</span>
					</div>
					<div class="GroupIndustry_cont_right_text2">
						<p>其他产品</p>
						<span>安全标识牌定制 | 劳动防护用品销售</span>
					</div>
				</div>
			</div>
		</div>
		<!-- 服务方案 -->
		<div class="AboutEnterprises" id="form3">
			<div class="AboutEnterprises_cont">
				<!-- title -->
				<div class="GroupIndustry_cont_left_title">
					<p>服务方案</p>
				</div>
				<!-- top -->
				<div class="AboutEnterprises_cont_top">
					<!-- img -->
					<div class="AboutEnterprises_cont_img_f">
						<div
							v-for="(item, index) in fuwufanganList"
							:key="index"
							class="AboutEnterprises_cont_img"
						>
							<router-link :to="item.url">
								<img :src="item.jituanimg" />
								<img
									:src="item.jituanimgyinying"
									class="AboutEnterprises_cont_img2"
								/>
								<div class="AboutEnterprises_cont_text">
									<span>{{ item.jituanspan }}</span>
									<p>{{ item.jituanp }}</p>
								</div>
							</router-link>
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<!-- 合作伙伴 -->
		<!-- <div class="CooperativePartner">
			<div class="CooperativePartner_cont">
				<h1 class="CooperativePartner_cont_title">合作伙伴</h1>
			</div>
			<div class="CooperativePartner_cont_center">
				<div class="CooperativePartner_cont_center_item">
					<img src="../assets/img/合作1.png" alt="" />
					<span>合作院校</span>
				</div>
				<div class="CooperativePartner_cont_center_item">
					<img src="../assets/img/合作2.png" alt="" />
					<span>合作院校</span>
				</div>
			</div>
			<div class="CooperativePartner_cont_bottom">
				<div
					v-for="(item, index) in logoList"
					:key="index"
					class="CooperativePartner_cont_bottom_logo"
				>
					<img :src="item.logo" alt="" />
				</div>
			</div>
		</div> -->

		<!-- footer -->
		<footer2></footer2>

		<!-- end -->
	</div>
</template>

<script>
import footer2 from "@/components/footer.vue";
export default {
	name: "index",
	components: {
		footer2,
	},
	data() {
		return {
			navList: [
				{ title: "首页", url: "/" },
				{ title: "公司简介", url: "/" },
				{ title: "业务范围", url: "/" },
				{ title: "服务方案", url: "/" },
				{ title: "联系我们", url: "/lianxi" },
				{ title: "招聘信息", url: "/zhaopinxinxi0" },
			],

			logoimg: require("../assets/img/logo2-3.jpg"),
			fuwufanganList: [
				{
					url: '/jituangaikuo1',
					jituanimg: require("../assets/img/fuwufangan/1.png"),				
				},
				{
					url: '/jituangaikuo2',
					jituanimg: require("../assets/img/fuwufangan/2.png"),					
				},
				{
					url: '/jituangaikuo3',
					jituanimg: require("../assets/img/fuwufangan/3.png"),				
				},
				{
					url: '/jituangaikuo4',
					jituanimg: require("../assets/img/fuwufangan/4.png"),
				},
				{
					url: '/jituangaikuo5',
					jituanimg: require("../assets/img/fuwufangan/5.png"),
				},
				{
					url: '/jituangaikuo6',
					jituanimg: require("../assets/img/fuwufangan/6.png"),
				},
				{
					url: '/jituangaikuo7',
					jituanimg: require("../assets/img/fuwufangan/7.png"),
				},
				{
					url: '/jituangaikuo8',
					jituanimg: require("../assets/img/fuwufangan/8.png"),
				}
			],
			jituanList: [
				{
					jituanimg: require("../assets/img/anquanpingjia.png"),
					jituanimgyinying: require("../assets/img/jituanyinying.png"),
					jituanspan: "安全评价类",
					jituanp: "安全生产条件设施综合分析  |安全预评价  |  安全设施竣工验收评价  |  安全现状评价",
				},
				{
					jituanimg: require("../assets/img/zhuangyeanquanpingjia.png"),
					jituanimgyinying: require("../assets/img/jituanyinying.png"),
					jituanspan: "专项安全评价",
					jituanp: "化学品专项安全评价 |易制毒、易制爆、剧毒化学品治安防范专项安全评价",
				},
				{
					jituanimg: require("../assets/img/anquanpinggu.png"),
					jituanimgyinying: require("../assets/img/jituanyinying.png"),
					jituanspan: "安全评估类",
					jituanp: "安全现状评估  |安全风险评估",
				},
				{
					jituanimg: require("../assets/img/sheji.png"),
					jituanimgyinying: require("../assets/img/jituanyinying.png"),
					jituanspan: "设计类",
					jituanp: "安全设施设计  |安全设计诊断",
				},
				{
					jituanimg: require("../assets/img/zixunfuwu.png"),
					jituanimgyinying: require("../assets/img/jituanyinying.png"),
					jituanspan: "咨询服务类",
					jituanp: "可行性研究报告/项目建议书  |生产安全事故应急预案/突发环境事件应急预案  |  安全生产标准化  |  安全风险分级管控和隐患排查治理机制",
				},
				{
					jituanimg: require("../assets/img/anquanguangjiafuwu.png"),
					jituanimgyinying: require("../assets/img/jituanyinying.png"),
					jituanspan: "安全管家服务类",
					jituanp: "第三方安全隐患排查(政府端)  |安全管家服务(企业端)  |  安全培训服务",
				},
			],
			logoList: [
				{ logo: require("../assets/img/xuexiao_logo.png") },
				{ logo: require("../assets/img/xuexiao_logo.png") },
				{ logo: require("../assets/img/xuexiao_logo.png") },
				{ logo: require("../assets/img/xuexiao_logo.png") },
				{ logo: require("../assets/img/xuexiao_logo.png") },
				{ logo: require("../assets/img/xuexiao_logo.png") },
				{ logo: require("../assets/img/xuexiao_logo.png") },
				{ logo: require("../assets/img/xuexiao_logo.png") },
				{ logo: require("../assets/img/xuexiao_logo.png") },
				{ logo: require("../assets/img/xuexiao_logo.png") },
				{ logo: require("../assets/img/xuexiao_logo.png") },
				{ logo: require("../assets/img/xuexiao_logo.png") },
				{ logo: require("../assets/img/xuexiao_logo.png") },
				{ logo: require("../assets/img/xuexiao_logo.png") },
				{ logo: require("../assets/img/xuexiao_logo.png") },
			],
			newList: [
				{
					title: "",
					time: "",
					text: "",
				},
			],
		};
	},
	computed: {
		title() {
			return this.$store.state.title;
		},
	},
	mounted () {
		const form1 = document.querySelector('#form1')
		window.scrollTo({
      top: form1.offsetTop,
      behavior: 'smooth'
    })
	},
	methods: {
		funyuming(msg) {
			this.$router.push(msg);
		},
		newsArticle(index) {
			this.$router.push("/newsxiangqing" + index);
		},
		funRouter() {
			this.$router.push("/keji");
		},
	},
};
</script>
<style scoped>
@charset "utf-8";
/*=========================Reset_start==========================*/
body,
h1,
h2,
h3,
h4,
h5,
h6,
div,
p,
dl,
dt,
dd,
ol,
ul,
li,
form,
table,
th,
td,
a,
img,
span,
strong,
var,
em,
input,
textarea,
select,
option {
	margin: 0;
	padding: 0;
}
/*去内外边距*/
html,
body {
	font-family: "微软雅黑", "宋体", Arail, Tabhoma;
	text-align: left;
}
/*字体 文本居左*/
ul {
	list-style: none;
}
/*去ul li 小点*/
img {
	border: 0 none;
}
/*img IE6 兼容问题*/
input,
select,
textarea {
	outline: 0;
}
/*去除点击蓝框*/
textarea {
	resize: none;
	overflow: auto;
}
/*锁定多行文本框大小 与文字超出出现滚动条*/
table {
	border-collapse: collapse;
	border-spacing: 0;
}
/*表格边框合并 边框间距0*/
th,
strong,
var,
em {
	font-weight: normal;
	font-style: normal;
}
/*特殊文字粗细 样式显示正常*/
a {
	text-decoration: none;
}
/*a标签去下划线*/
.clearfix:after {
	clear: both;
	content: "";
	display: block;
	height: 0;
}
/*清浮动*/
a {
	color: #000000;
}
/*==========================Reset_End===========================*/

.homepage_wrap {
	width: 100vw;
	margin: 0 auto;
	height: 1000px;
}

.banner_nav {
	width: 100vw;
	height: 800px;
	position: relative;
}

.banner_nav_bg img {
	/* 文本居顶部对齐 */
	vertical-align: top;
}

/* 导航部分 */

.navWrap {
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	/*  */
	padding-top: 40px;
}

.navWrap_center {
	width: 1440px;
	height: 101px;
	margin: 0 auto;
	/* background: #fff; */
	border-radius: 51px;
	/*  */
	display: flex;
	justify-content: space-between;
	background: rgba(255, 255, 255, 0.5);
}

.navWrap_center_img {
	width: 181px;
	height: 54px;
	margin: 23px 0 0 120px;
}

.navWrap_center_img img {
	width: 181px;
}

.navWrap_center_ul {
	/* border:1px solid red; */
	margin-right: 121px;
}

.navWrap_center_ul li {
	display: inline-block;
	height: 98px;
	line-height: 98px;
	/* border-bottom: 4px solid blue; */
	margin-left: 50px;
	padding: 0 5px;
	font-size: 16px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #222222;
	/* line-height: 22px; */
}
.navWrap_center_ul li:hover {
	cursor: pointer;
	color: rgba(35, 51, 127, 1);
	border-bottom: 2px solid rgba(35, 51, 127, 1);
}

/* title */

.banner_title_wrap {
	width: 100%;
	position: absolute;
	bottom: 190px;
	/* border: 1px solid yellow; */
}

.banner_title_center {
	/* border: 1px solid white; */
	text-align: center;
	width: 1200px;
	margin: 0 auto;
	font-size: 90px;
	font-family: AlibabaPuHuiTi-Bold, AlibabaPuHuiTi;
	font-weight: bold;
	color: #ffffff;
	line-height: 124px;
	letter-spacing: 1px;
}

.banner_title_center div {
	padding-top: 14px;
	font-size: 20px;
	font-family: Helvetica;
	line-height: 24px;
	letter-spacing: 4px;
}

.banner_title_center div span {
	margin: 0 18px;
}
/* news */
.news {
	width: 100vw;
	height: 801px;
	background: #f8f8f8;
}
.news_cont {
	width: 1200px;
	margin: 0 auto;
	display: flex;
	justify-content: space-between;
}
.news_cont_left {
	margin-top: 89px;
}
.news_cont_left_img img {
	width: 598px;
	height: 353px;
	margin-top: 41px;
}
.news_cont_left_text {
	padding-top: 27px;
	padding-left: 40px;
	width: 548px;
	height: 150px;
	background-color: #ffffff;
	padding-right: 10px;
	margin-right: 35px;
}
.news_cont_left_text h1 {
	font-size: 18px;
	font-weight: 500;
	color: #23337f;
}
.news_cont_left_text p {
	font-size: 14px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #000000;
	margin-top: 10px;
}
.news_cont_right {
	width: 588px;
	margin-top: 103px;
}
.news_cont_right_ul ul {
	font-size: 16px;
	font-weight: 400;
	color: #b3b3b3;
	line-height: 22px;
	float: right;
	display: flex;
}
.news_cont_right_ul ul a {
	font-size: 16px;
	font-weight: 400;
	color: #b3b3b3;
	line-height: 22px;
	float: right;
	display: flex;
}
.news_cont_right_ul ul li:hover {
	color: #23337f;
}
.news_cont_right_ul ul span {
	margin: 0 23px 0 24px;
}
.news_cont_right_text {
	width: 578px;
	height: 509px;
	margin-top: 68px;
	padding-top: 27px;
	padding-left: 40px;
	background-color: #ffffff;
	margin-right: 35px;
}
.news_cont_right_text p {
	font-size: 14px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #000000;
	margin-top: 10px;
}
.news_cont_right_text_item {
	padding: 36px;
}
.news_cont_right_text_item h1 {
	font-size: 18px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	color: #444444;
	line-height: 25px;
}
.news_cont_right_text_item p {
	margin-top: 10px;
	font-size: 14px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #8f8f8f;
	line-height: 20px;
}
.news_cont_right_text_hr {
	/* float: right; */
	width: 508px;

	border-bottom: 1px solid #d7d7d7;
}
.news_cont_right_text_hr:nth-of-type(3) {
	/* float: right; */
	width: 508px;

	border-bottom: none;
}
.news_cont_right_text_item2 {
	padding: 24px 40px;
}
.news_cont_right_text_item2 h1 {
	font-size: 18px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	color: #444444;
	line-height: 25px;
}
.news_cont_right_text_item2 p {
	margin-top: 10px;
	font-size: 14px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #8f8f8f;
	line-height: 20px;
}
/* 业务范围 */
.GroupIndustry {
	width: 100vw;
	height: 1050px;
	background: #ffffff;
}
.GroupIndustry_cont {
	width: 1200px;
	margin: 0 auto;
	display: flex;
	justify-content: space-between;
}
.GroupIndustry_cont_left_title {
	font-size: 36px;
	font-family: PingFangSC-Semibold, PingFang SC;
	font-weight: 600;
	color: #444444;
	line-height: 50px;
	margin: 84px 0 40px 0;
}
.GroupIndustry_cont_left_img_f {
	width: 793px;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}
.GroupIndustry_cont_left_img {
	margin-bottom: 24px;

	position: relative;
}
.GroupIndustry_cont_left_img img:first-child {
	width: 384px;
	height: 261px;
	background: linear-gradient(180deg, rgba(0, 100, 255, 0) 0%, #000b6e 100%);
}
.GroupIndustry_cont_left_img2 {
	position: absolute;
	left: 0;
	bottom: 0;
}

.GroupIndustry_cont_left_text {
	position: absolute;
	left: 32px;
	bottom: 18px;
}
.GroupIndustry_cont_left_text p {
	font-size: 14px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #ffffff;
	line-height: 20px;
	margin-top: 10px;
}
.GroupIndustry_cont_left_text span {
	font-size: 24px;
	font-family: PingFangSC-Semibold, PingFang SC;
	font-weight: 600;
	color: #ffffff;
	text-shadow: 0px 2px 6px rgba(0, 0, 0, 0.3);
}
.GroupIndustry_cont_right {
	position: relative;
	margin-top: 173px;
}
/* .GroupIndustry_cont_right_title {
	float: right;
	font-size: 16px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #999999;
	line-height: 22px;
	margin-bottom: 54px;
} */

.GroupIndustry_cont_right_img {
	width: 384px;
	height: 546px;
}
.GroupIndustry_cont_right_img2 {
	position: absolute;
	bottom: 24px;
}
.GroupIndustry_cont_right_text {
	position: absolute;
	left: 45px;
	bottom: 140px;
}
.GroupIndustry_cont_right_text p {
	font-size: 24px;
	font-family: PingFangSC-Semibold, PingFang SC;
	font-weight: 600;
	color: #ffffff;
	line-height: 33px;
}
.GroupIndustry_cont_right_text span {
	font-size: 14px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #ffffff;
	line-height: 20px;
}
.GroupIndustry_cont_right_text2 {
	position: absolute;
	left: 45px;
	bottom: 45px;
}
.GroupIndustry_cont_right_text2 p {
	font-size: 24px;
	font-family: PingFangSC-Semibold, PingFang SC;
	font-weight: 600;
	color: #ffffff;
	line-height: 33px;
}
.GroupIndustry_cont_right_text2 span {
	font-size: 14px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #ffffff;
	line-height: 20px;
}
/* 服务方案 */
.AboutEnterprises {
	width: 100vw;
	height: 856px;
	background-image: url("../assets/img/fuwufangan/background.png");
	padding-top: 1px;
}
.AboutEnterprises_cont {
	width: 1200px;
	margin: 0 auto;
	height: 100%;
}
.AboutEnterprises_cont_top {
	display: flex;
	justify-content: space-between;
}
.AboutEnterprises_cont_top_left {
	margin-top: 143px;
	width: 600px;
}
.AboutEnterprises_cont_top_left > h1 {
	font-size: 36px;
	font-family: PingFangSC-Semibold, PingFang SC;
	font-weight: 600;
	color: #ffffff;
	line-height: 50px;
}
.AboutEnterprises_cont_top_left p {
	margin-top: 36px;
	font-size: 16px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #ffffff;
	line-height: 40px;
}
.AboutEnterprises_cont_top_left_btn {
	width: 160px;
	height: 40px;
	border: 1px solid rgba(255, 255, 255, 0.8);
	margin-top: 81px;
	display: flex;
	align-items: center;
}
.AboutEnterprises_cont_top_left_btn img {
	width: 24px;
	height: 24px;
	margin-left: 8px;
}
.AboutEnterprises_cont_top_left_btn span {
	margin-left: 32px;
	font-size: 16px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #000;
	line-height: 22px;
}
.AboutEnterprises_cont_top_right {
	position: relative;
	width: 487px;
	height: 517px;
	margin-top: 115px;
}
.AboutEnterprises_cont_top_right img {
	position: absolute;
	z-index: 1;
}
.AboutEnterprises_cont_top_right Div {
	width: 317px;
	height: 408px;
	background: #3491e5;
	position: absolute;
	top: 64px;
	left: -33px;
}
.AboutEnterprises_cont_bottom {
	display: flex;
	margin-top: 80px;
}
.AboutEnterprises_cont_bottom_item {
	margin-right: 70px;
	position: relative;
	width: 247px;
	border-right: 1px solid #ffffff;
	display: flex;
}
.AboutEnterprises_cont_bottom_item3 {
	margin-right: 70px;
	position: relative;
	width: 323px;
	border-right: 1px solid #ffffff;
	display: flex;
}
.AboutEnterprises_cont_bottom_item3 p {
	width: 86px;
	height: 28px;
	font-size: 20px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	color: #b30588;
	line-height: 28px;
}
.AboutEnterprises_cont_bottom_item3 h1 {
	font-size: 60px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	color: #ffffff;
	line-height: 84px;
}
.AboutEnterprises_cont_bottom_item3 span {
	font-size: 17px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #692020;
	line-height: 24px;
}
.AboutEnterprises_cont_bottom_item4 {
	position: absolute;
	left: 161px;
	bottom: 15px;
}

.AboutEnterprises_cont_bottom_item h1 {
	font-size: 60px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	color: #ffffff;
	line-height: 84px;
}
.AboutEnterprises_cont_bottom_item span {
	font-size: 17px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #692020;
	line-height: 24px;
}
.AboutEnterprises_cont_bottom_item p {
	width: 86px;
	height: 28px;
	font-size: 20px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	color: #b30588;
	line-height: 28px;
}

.AboutEnterprises_cont_bottom_item2 {
	position: absolute;
	left: 85px;
	bottom: 15px;
}
.AboutEnterprises_cont_img_f {
	width: 100%;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}
.AboutEnterprises_cont_img {
	margin-bottom: 24px;
	position: relative;
}
.AboutEnterprises_cont_img2 {
	position: absolute;
	left: 0;
	bottom: 0;
}
.AboutEnterprises_cont_text {
	position: absolute;
	left: 32px;
	bottom: 18px;
}
.AboutEnterprises_cont_text p {
	font-size: 14px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #ffffff;
	line-height: 20px;
	margin-top: 10px;
}
.AboutEnterprises_cont_text span {
	font-size: 24px;
	font-family: PingFangSC-Semibold, PingFang SC;
	font-weight: 600;
	color: #ffffff;
	text-shadow: 0px 2px 6px rgba(0, 0, 0, 0.3);
}
/* 合作伙伴 */
.CooperativePartner {
	width: 100vw;
	height: 798px;
	background-image: url("../assets/img/hezuo_bg.png");
}
.CooperativePartner_cont {
	width: 1200px;
	margin: 0 auto;
}
.CooperativePartner_cont_title {
	text-align: center;
	margin-top: 80px;
	font-size: 36px;
	font-family: PingFangSC-Semibold, PingFang SC;
	font-weight: 600;
	color: #444444;
	line-height: 50px;
}
.CooperativePartner_cont_center {
	display: flex;
	justify-content: space-between;
	width: 365px;
	height: 69px;

	margin: 0 auto;
	margin-top: 47px;
}
.CooperativePartner_cont_center_item {
	display: flex;
	align-items: center;
}
.CooperativePartner_cont_center_item:hover {
	border-bottom: 1px solid #3491e5;
}
.CooperativePartner_cont_center_item img {
	width: 40px;
	height: 40px;
}
.CooperativePartner_cont_center_item span {
	line-height: 69px;
	font-size: 24px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #23337f;
	line-height: 33px;
	margin-left: 14px;
}
.CooperativePartner_cont_bottom {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	width: 1200px;
	height: 392px;
	margin: 0 auto;
	margin-top: 80px;
}
.CooperativePartner_cont_bottom_logo img {
	width: 227px;
	height: 120px;
	background: #ffffff;
	box-shadow: 0px 0px 12px -2px rgba(0, 0, 0, 0.12);
	border-radius: 9px;
	margin-bottom: 16px;
}
.dropbtn {
	background-color: #fff;
	color: rgb(12, 11, 11);
	padding: 16px;
	font-size: 16px;
	border: none;
	cursor: pointer;
}

.dropdown {
	top: 22px;
	left: 394px;
	position: relative;
	display: inline-block;
}

.dropdown-content {
	text-align: center;
	display: none;
	position: absolute;
	background-color: #f9f9f9;
	min-width: 95px;
	min-height: 120px;
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

.dropdown-content a {
	color: rgb(6, 6, 6);
	padding: 12px 16px;
	text-decoration: none;
	display: block;
}

.dropdown-content a:hover {
	background-color: #f1f1f1;
}

.dropdown:hover .dropdown-content {
	display: block;
}
</style>
