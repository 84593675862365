<template>
	<div class="GroupOverview">
		<daohang></daohang>
		<!-- 服务方案 -->
		<div class="about-bg">
			<div class="about" id="about">
				<div class="about-top">
					<div class="about-top-l">
						<span style="font-weight: bolder;">6.安全生产标准化</span>
						<p>
							<strong style="font-weight: bolder;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;法规依据：</strong>《关于印发〈企业安全生产标准化建设定级办法〉的通知》应急〔2021〕83号、《安徽省企业安全生产标准化建设定级实施办法》皖应急〔2022〕18号、《企业安全生产标准化基本规范》GB/T 33000-2016
						</p>
						<p>
							&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;申请对象：符合安徽省企业安全生产标准化建设定级实施办法中第九条规定的本省行政区域内的化工（含石油化工）、医药、危险化学品、烟花爆竹、冶金、有色、建材、机械、轻工、纺织、烟草、商贸等行业企业。
						</p>
						<div>
							<p>
								<span style="font-size: 16px;line-height: 40px;">
									<strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;定义</strong>：是指通过建立安全生产责任制，制定安全管理制度和操作规程，排查治理隐患和监控重大危险源，建立预防机制，规范生产行为，使各生产环节符合有关安全生产法律法规和标准规范的要求，人（人员）、机（机械）、料（材料）、法（工法）、环（环境）处于良好的生产状态，并持续改进，不断加强企业安全生产规范化建设。
								</span>
							</p>
							<p>
								<span style="font-size: 16px;line-height: 40px;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;安全生产标准化主要包含目标职责、制度化管理、教育培训、现场管理、安全风险管控及隐患排查治理、应急管理、事故管理、持续改进8个方面。</span>
							</p>
							<p>
								<span style="font-size: 16px;line-height: 40px;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;企业标准化等级分为一级、二级、三级。</span>
							</p>
							<p>
								<span style="font-size: 16px;line-height: 40px;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;企业标准化定级标准由应急管理部按照行业分别制定参照《企业安全生产标准化基本规范》（GB／T 33000）配套的定级标准，在本行政区域内开展二级、三级企业建设工作。企业标准化定级实行分级负责。定级按照自评、申请、评审、公示、公告的程序进行。</span>
							</p>
							<p>
								<span style="font-size: 16px;line-height: 40px;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;评价周期：60个工作日</span>
							</p>
							<p>
								<span style="font-size: 16px;line-height: 40px; font-weight: bolder;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;注：定级评审流程及各系统阶段操作图</span>
							</p>
							<div style="margin-bottom: 20px;">
								<img src="../assets/img/fuwufangan/图片1.png" alt=""/>
							</div>
							<table
								style="width: 1000px; height: 69px; border: 1px solid #000; border-collapse: collapse;" 
								width="470.0" 
								cellspacing="0" 
								cellpadding="0">
								<tbody>
									<tr>
										<td style="width: 650.641px; border-width: 1px; border-color: #000; 
										padding: 0px 5px; text-align: center; height: 50px; border-bottom: 1px solid #000; 
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="font-size: 16px;">阶段</span>
											</div>
										</td>
										<td style="width: 630.641px; border-width: 1px; border-color: #000; 
										padding: 0px 5px; text-align: center; height: 50px; border-bottom: 1px solid #000; 
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div >
												<span style="font-size: 16px;">对应权限用户</span>
											</div>
										</td>
										<td style="width: 630.641px; border-width: 1px; border-color: #000;
										padding: 0px 5px; text-align: center; height: 50px; border-bottom: 1px solid #000; 
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div >
												<span style="font-size: 16px;">可做操作</span>
											</div>
										</td>
										<td style="width: 1100.641px; border-width: 1px; border-color: #000;
										padding: 0px 5px; text-align: center; height: 50px; border-bottom: 1px solid #000; 
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div >
												<span style="font-size: 16px;">操作描述</span>
											</div>
										</td>
									</tr>
									<tr style="text-align: center;">
										<td style="width: 650.359px; border-width: 1px; border-color: #000; 
										padding: 0px 5px; height: 50px; border-bottom: 1px solid #000; 
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="font-size: 16px;">申请</span>
											</div>
										</td>
										<td style="width: 630.641px; border-width: 1px; border-color: #000; 
										padding: 0px 5px; height: 50px; border-bottom: 1px solid #000; 
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="font-size: 16px;">企业</span>
											</div>
										</td>
										<td style="width: 630.641px; border-width: 1px; border-color: #000;
											height: 50px; border-bottom: 1px solid #000; 
											border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div style="border-bottom: 1px solid black;">
												<span style="font-size: 16px; vertical-align: middle;">提交申请</span>
											</div>
											<div>
												<span style="font-size: 16px; vertical-align: middle;">撤回申请</span>
											</div>
										</td>
										<td style="width: 50%; border-width: 1px; border-color: #000;
										height: 50px; border-bottom: 1px solid #000; 
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div style="border-bottom: 1px solid black;">
												<span style="font-size: 16px;">提交至所选评审组织单位受理或拥有评审权限的定级部门受理</span>
											</div>
											<div>
												<span style="font-size: 16px;">点击后撤回至企业用户重新提交申请</span>
											</div>
										</td>
									</tr>
									<tr style="text-align: center;">
										<td style="width: 650.359px; border-width: 1px; border-color: #000; 
										padding: 0px 5px; height: 50px; border-bottom: 1px solid #000; 
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="font-size: 16px;">受理阶段</span>
											</div>
										</td>
										<td style="width: 630.641px; border-width: 1px; border-color: #000; 
										padding: 0px 5px; height: 50px; border-bottom: 1px solid #000; 
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="ont-size: 16px;">评审组织单位</span>
											</div>
										</td>
										<td style="width: 630.641px; border-width: 1px; border-color: #000;
										height: 50px; border-bottom: 1px solid #000; 
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div style="border-bottom: 1px solid black;">
												<span style="ont-size: 16px;">予以受理</span>
											</div>
											<div style="border-bottom: 1px solid black;">
												<span style="ont-size: 16px;">不予受理</span>
											</div>
											<div>
												<span style="ont-size: 16px;">退回企业补正材料</span>
											</div>
										</td>
										<td style="width: 50%; border-width: 1px; border-color: #000;
										height: 50px; border-bottom: 1px solid #000; 
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div style="border-bottom: 1px solid black;">
												<span style="ont-size: 16px;">提交后将企业申请信息推送至定级部门进行审核</span>
											</div>
											<div style="border-bottom: 1px solid black;">
												<span style="ont-size: 16px;">提交后此次企业申请的定级申请流程直接终止,一年内不能再次申请</span>
											</div>
											<div>
												<span style="ont-size: 16px;">提交后退回至企业端补充申请材料,企业可再次提交申请进行受理</span>
											</div>
										</td>
									</tr>
									<tr style="text-align: center;">
										<td style="width: 650.359px; border-width: 1px; border-color: #000; 
										padding: 0px 5px; height: 50px; border-bottom: 1px solid #000; 
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="font-kerning: none; font-size: 16px;">确认组织单位审核意见和企业自评报告</span>
											</div>
										</td>
										<td style="width: 630.641px; border-width: 1x; border-color: #000; 
										padding: 0px 5px; height: 50px; border-bottom: 1px solid #000; 
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="ffont-size: 16px;">定级部门</span>
											</div>
										</td>
										<td style="width: 630.641px; border-width: 1x; border-color: #000;
										height: 50px; border-bottom: 1px solid #000; 
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div style="border-bottom: 1px solid black;">
												<span style="ffont-size: 16px;">审核通过</span>
											</div>
											<div>
												<span style="ffont-size: 16px;">审核不通过</span>
											</div>
										</td>
										<td style="width: 50%; border-width: 1x; border-color: #000;
										height: 50px; border-bottom: 1px solid #000; 
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div style="border-bottom: 1px solid black;">
												<span style="ffont-size: 16px;">提交后推送至评审组织单位端选择评审单位进行现场评审</span>
											</div>
											<div>
												<span style="ffont-size: 16px;">提交后退回至评审组织单位端进行重新评审</span>
											</div>
										</td>
									</tr>
									<tr style="text-align: center;">
										<td style="width: 650.359px; border-width: 1px; border-color: #000; 
										padding: 0px 5px; height: 50px; border-bottom: 1px solid #000; 
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="font-kerning: none; font-size: 16px;">通知负责现场<br/>评审的单位评审</span>
											</div>
										</td>
										<td style="width: 630.641px; border-width: 1x; border-color: #000; 
										padding: 0px 5px; height: 50px; border-bottom: 1px solid #000; 
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="ffont-size: 16px;">评审组织单位</span>
											</div>
										</td>
										<td style="width: 630.641px; border-width: 1x; border-color: #000;
										height: 50px; border-bottom: 1px solid #000; 
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div style="border-bottom: 1px solid black;">
												<span style="ffont-size: 16px;">选择评审单位</span>
											</div>
											<div>
												<span style="ffont-size: 16px;">直接终止</span>
											</div>
										</td>
										<td style="width: 50%; border-width: 1x; border-color: #000;
										height: 50px; border-bottom: 1px solid #000; 
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div style="border-bottom: 1px solid black;">
												<span style="ffont-size: 16px;">提交后会推送至选择评审单位端进行现场评审</span>
											</div>
											<div>
												<span style="ffont-size: 16px;">点击后企业此次申请定级流程直接终止,一年内不能在次申请</span>
											</div>
										</td>
									</tr>
									<tr style="text-align: center;">
										<td style="width: 650.359px; border-width: 1px; border-color: #000; 
										padding: 0px 5px; height: 50px; border-bottom: 1px solid #000; 
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="font-kerning: none; font-size: 16px;">评审阶段</span>
											</div>
										</td>
										<td style="width: 630.641px; border-width: 1x; border-color: #000; 
										padding: 0px 5px; height: 50px; border-bottom: 1px solid #000; 
										border-right: #000; border: 1px solid #000;" valign="top">
											<div>
												<span style="ffont-size: 16px;">评审单位</span>
											</div>
										</td>
										<td style="width: 630.641px; border-width: 1x; border-color: #000;
										height: 50px; border-bottom: 1px solid #000; 
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div style="border-bottom: 1px solid black;">
												<span style="ffont-size: 16px;">达到拟申请等级</span>
											</div>
											<div style="border-bottom: 1px solid black;">
												<span style="ffont-size: 16px;">退回评审组织单位</span>
											</div>
											<div>
												<span style="ffont-size: 16px;">重新通知评审</span>
											</div>
										</td>
										<td style="width: 50%; border-width: 1x; border-color: #000;
										height: 50px; border-bottom: 1px solid #000; 
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div style="border-bottom: 1px solid black;">
												<span style="ffont-size: 16px;">提交后推送至评审单位确认是否需要整改</span>
											</div>
											<div style="border-bottom: 1px solid black;">
												<span style="ffont-size: 16px;">提交后此次企业申请的定级申请流程直接终止,一年内不能再次申请</span>
											</div>
											<div>
												<span style="ffont-size: 16px;">提交后退回至评审组织单位端重新选择评审单位</span>
											</div>
										</td>
									</tr>
									<tr style="text-align: center;">
										<td style="width: 650.359px; border-width: 1px; border-color: #000; 
										padding: 0px 5px; height: 50px; border-bottom: 1px solid #000; 
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="font-kerning: none; font-size: 16px;">是否需要整改</span>
											</div>
										</td>
										<td style="width: 630.641px; border-width: 1x; border-color: #000; 
										padding: 0px 5px; height: 50px; border-bottom: 1px solid #000; 
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="ffont-size: 16px;">评审单位</span>
											</div>
										</td>
										<td style="width: 630.641px; border-width: 1x; border-color: #000;
										height: 50px; border-bottom: 1px solid #000; 
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div style="border-bottom: 1px solid black;">
												<span style="ffont-size: 16px;">需要整改</span>
											</div>
											<div>
												<span style="ffont-size: 16px;">不需要整改</span>
											</div>
										</td>
										<td style="width: 50%; border-width: 1x; border-color: #000;
										height: 50px; border-bottom: 1px solid #000; 
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div style="border-bottom: 1px solid black;">
												<span style="ffont-size: 16px;">提交后推送至企业端上传整改保报告</span>
											</div>
											<div>
												<span style="ffont-size: 16px;">提交后推送至评审单位进行录入评审结果</span>
											</div>
										</td>
									</tr>
									<tr style="text-align: center;">
										<td style="width: 650.359px; border-width: 1px; border-color: #000; 
										padding: 0px 5px; height: 50px; border-bottom: 1px solid #000; 
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="font-kerning: none; font-size: 16px;">企业整改反馈</span>
											</div>
										</td>
										<td style="width: 630.641px; border-width: 1x; border-color: #000; 
										padding: 0px 5px; height: 50px; border-bottom: 1px solid #000; 
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="ffont-size: 16px;">企业</span>
											</div>
										</td>
										<td style="width: 630.641px; border-width: 1x; border-color: #000;
										padding: 0px 5px; height: 50px; border-bottom: 1px solid #000; 
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="ffont-size: 16px;">上传整改报告</span>
											</div>
										</td>
										<td style="width: 50%; border-width: 1x; border-color: #000;
										padding: 0px 5px; height: 50px; border-bottom: 1px solid #000; 
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="ffont-size: 16px;">提交后推送至评审单位进行评审是否整改通过</span>
											</div>
										</td>
									</tr>
									
									<tr style="text-align: center;">
										<td style="width: 650.359px; border-width: 1px; border-color: #000; 
										padding: 0px 5px; height: 50px; border-bottom: 1px solid #000; 
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="font-size: 16px;">是否整改通过</span>
											</div>
										</td>
										<td style="width: 630.641px; border-width: 1px; border-color: #000; 
										padding: 0px 5px; height: 50px; border-bottom: 1px solid #000; 
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="font-size: 16px;">评审单位</span>
											</div>
										</td>
										<td style="width: 630.641px; border-width: 1px; border-color: #000;
										padding: 0px 5px; height: 50px; border-bottom: 1px solid #000; 
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="font-size: 16px;">整改通过</span>
											</div>
											<div>
												<span style="font-size: 16px;">整改不通过</span>
											</div>
										</td>
										<td style="width: 50%; border-width: 1px; border-color: #000;
										padding: 0px 5px; height: 50px; border-bottom: 1px solid #000; 
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="font-size: 16px;">提交后推送至评审单位进行录入评审结果</span>
											</div>
											<div>
												<span style="font-size: 16px;">提交后此次企业申请的定级申请流程直接终止,一年内不能再次申请</span>
											</div>
										</td>
									</tr>
									<tr style="text-align: center;">
										<td style="width: 650.359px; border-width: 1px; border-color: #000; 
										padding: 0px 5px; height: 50px; border-bottom: 1px solid #000; 
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="font-size: 16px;">录入评审结果</span>
											</div>
										</td>
										<td style="width: 630.641px; border-width: 1px; border-color: #000; 
										padding: 0px 5px; height: 50px; border-bottom: 1px solid #000; 
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="ont-size: 16px;">评审单位</span>
											</div>
										</td>
										<td style="width: 630.641px; border-width: 1px; border-color: #000;
										padding: 0px 5px; height: 50px; border-bottom: 1px solid #000; 
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="ont-size: 16px;">录入评审结果</span>
											</div>
										</td>
										<td style="width: 50%; border-width: 1px; border-color: #000;
										padding: 0px 5px; height: 50px; border-bottom: 1px solid #000; 
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="ont-size: 16px;">提交后推送至评审组织单位进行确认评审结果</span>
											</div>
										</td>
									</tr>
									<tr style="text-align: center;">
										<td style="width: 650.359px; border-width: 1px; border-color: #000; 
										padding: 0px 5px; height: 50px; border-bottom: 1px solid #000; 
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="font-kerning: none; font-size: 16px;">组织单位确认<br/>现场评审结果</span>
											</div>
										</td>
										<td style="width: 630.641px; border-width: 1x; border-color: #000; 
										padding: 0px 5px; height: 50px; border-bottom: 1px solid #000; 
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="ffont-size: 16px;">评审组织单位</span>
											</div>
										</td>
										<td style="width: 630.641px; border-width: 1x; border-color: #000;
										padding: 0px 5px; height: 50px; border-bottom: 1px solid #000; 
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="ffont-size: 16px;">报送定级部门</span>
											</div>
										</td>
										<td style="width: 50%; border-width: 1x; border-color: #000;
										padding: 0px 5px; height: 50px; border-bottom: 1px solid #000; 
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="ffont-size: 16px;">提交后推送至定级部门进行名单公示</span>
											</div>
										</td>
									</tr>
									<tr style="text-align: center;">
										<td style="width: 650.359px; border-width: 1px; border-color: #000; 
										padding: 0px 5px; height: 50px; border-bottom: 1px solid #000; 
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="font-kerning: none; font-size: 16px;">名单确认</span>
											</div>
										</td>
										<td style="width: 630.641px; border-width: 1x; border-color: #000; 
										padding: 0px 5px; height: 50px; border-bottom: 1px solid #000; 
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="ffont-size: 16px;">定级部门</span>
											</div>
										</td>
										<td style="width: 630.641px; border-width: 1x; border-color: #000;
										padding: 0px 5px; height: 50px; border-bottom: 1px solid #000; 
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="ffont-size: 16px;">同意公示</span>
											</div>
										</td>
										<td style="width: 50%; border-width: 1x; border-color: #000;
										padding: 0px 5px; height: 50px; border-bottom: 1px solid #000; 
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="ffont-size: 16px;">提交后推送至定级部门进行公示</span>
											</div>
										</td>
									</tr>
									<tr style="text-align: center;">
										<td style="width: 650.359px; border-width: 1px; border-color: #000; 
										padding: 0px 5px; height: 50px; border-bottom: 1px solid #000; 
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="font-kerning: none; font-size: 16px;">公示阶段</span>
											</div>
										</td>
										<td style="width: 630.641px; border-width: 1x; border-color: #000; 
										padding: 0px 5px; height: 50px; border-bottom: 1px solid #000; 
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="ffont-size: 16px;">定级部门</span>
											</div>
										</td>
										<td style="width: 630.641px; border-width: 1x; border-color: #000;
										height: 50px; border-bottom: 1px solid #000; 
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div style="border-bottom: 1px solid black;">
												<span style="ffont-size: 16px;">存在</span>
											</div>
											<div>
												<span style="ffont-size: 16px;">不存在</span>
											</div>
										</td>
										<td style="width: 50%; border-width: 1x; border-color: #000;
										height: 50px; border-bottom: 1px solid #000; 
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div style="border-bottom: 1px solid black;">
												<span style="ffont-size: 16px;">提交后推送至定级部门进行反应问题核实</span>
											</div>
											<div>
												<span style="ffont-size: 16px;">不存在	提交后推送至定级部门进行公告</span>
											</div>
										</td>
									</tr>
									<tr style="text-align: center;">
										<td style="width: 650.359px; border-width: 1px; border-color: #000; 
										padding: 0px 5px; height: 50px; border-bottom: 1px solid #000; 
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="font-kerning: none; font-size: 16px;">组织核实</span>
											</div>
										</td>
										<td style="width: 630.641px; border-width: 1x; border-color: #000; 
										padding: 0px 5px; height: 50px; border-bottom: 1px solid #000; 
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="ffont-size: 16px;">定级部门</span>
											</div>
										</td>
										<td style="width: 630.641px; border-width: 1x; border-color: #000;
										height: 50px; border-bottom: 1px solid #000; 
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div style="border-bottom: 1px solid black;">
												<span style="ffont-size: 16px;">反映问题情况属实</span>
											</div>
											<div>
												<span style="ffont-size: 16px;">反映问题情况不属实</span>
											</div>
										</td>
										<td style="width: 50%; border-width: 1x; border-color: #000;
										height: 50px; border-bottom: 1px solid #000; 
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="ffont-size: 16px;">提交后此次企业申请的定级申请流程直接终止,一年内不能再次申请</span>
											</div>
											<div>
												<span style="ffont-size: 16px;">提交后推送至定级部门进行公告</span>
											</div>
										</td>
									</tr>
									<tr style="text-align: center;">
										<td style="width: 650.359px; border-width: 1px; border-color: #000; 
										padding: 0px 5px; height: 50px; border-bottom: 1px solid #000; 
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="font-kerning: none; font-size: 16px;">公告阶段</span>
											</div>
										</td>
										<td style="width: 630.641px; border-width: 1x; border-color: #000; 
										padding: 0px 5px; height: 50px; border-bottom: 1px solid #000; 
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="ffont-size: 16px;">定级部门</span>
											</div>
										</td>
										<td style="width: 630.641px; border-width: 1x; border-color: #000;
										height: 50px; border-bottom: 1px solid #000; 
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div style="border-bottom: 1px solid black;">
												<span style="ffont-size: 16px;">同意公告</span>
											</div>
											<div>
												<span style="ffont-size: 16px;">不同意公告</span>
											</div>
										</td>
										<td style="width: 50%; border-width: 1x; border-color: #000;
										height: 50px; border-bottom: 1px solid #000; 
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div style="border-bottom: 1px solid black;">
												<span style="ffont-size: 16px;">提交后流程结束</span>
											</div>
											<div>
												<span style="ffont-size: 16px;">交后此次企业申请的定级申请流程直接终止,一年内不能再次申请</span>
											</div>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 底部 -->
		<footer2></footer2>
	</div>
</template>

<script>
import daohang from "../components/nav.vue";
import footer2 from "../components/footer.vue";
export default {
	name: "App",
	data() {
		return {
			h1: "集团概况",
			P1: "欢迎加入我们",
			Img1: require("../assets/img/bannerjituan.png"),
		};
	},
	components: {
		daohang,
		footer2,
	},
	created() {
		this.$nextTick(() => {
			this.toLocal();
		});
	},
	mounted() {
		let _this = this;
		_this.$nextTick(function () {
			window.addEventListener("scroll", _this.handleScroll);
		});
	},
	methods: {
		toLocal() {
			//查找存储的锚点id
			let Id = localStorage.getItem("toId");
			let toElement = document.getElementById(Id);
			//锚点存在跳转
			if (Id) {
				toElement.scrollIntoView();
				localStorage.setItem("toId", "");
			}
		},
	},
};
</script>

<style scoped>
.GroupOverview {
	width: 100vw;
	margin: 0 auto;
}
.about {
	height: 2500px;
	position: relative;
	margin: 0px 200px;
}

.about-bg {
	background-image: url("../assets/img/蒙版.png");
}

.about-top {
	position: relative;
	display: flex;
}

.about-top-l {
	position: absolute;
	left: 20px;
	top: 85px;
	width: 100%;
	height: 442px;
}

.about-top-l > span {
	font-size: 32px;
}

.about-top-l > p {
	font-size: 16px;
	line-height: 40px;
}

.img-yinying {
	position: absolute;
	top: 58px;
	left: -17px;
	width: 17px;
	height: 408px;
	background: #f8f8f8;
}

.about-top-r {
	position: absolute;
	right: -110px;
}

.about-top-r img {
	width: 487px;
	height: 517px;
}

.about-down {
	position: absolute;
	bottom: 20px;
}

.item {
	top: 236px;
	position: relative;
}

.item2 {
	top: 158px;
	left: 328px;
	position: relative;
}

.item3 {
	top: 79px;
	left: 645px;
	position: relative;
}

.item4 {
	left: 1049px;
	position: relative;
}

.item-1 {
	color: rgba(35, 51, 127, 1);
	font-size: 60px;
}

.item-2 {
	position: absolute;
	color: rgba(35, 51, 127, 1);
	font-size: 16.8px;
}

.item-3 {
	position: absolute;
	left: 88px;
	top: 41px;
	color: rgba(102, 102, 102, 1);
	font-size: 20px;
	width: 100px;
}

.item2-3 {
	position: relative;
	color: rgba(102, 102, 102, 1);
	left: 18px;
	font-size: 20px;
}

.item3-3 {
	position: relative;
	color: rgba(102, 102, 102, 1);
	left: 18px;
	font-size: 20px;
}

.item4-3 {
	position: relative;
	color: rgba(102, 102, 102, 1);
	left: 18px;
	font-size: 20px;
}

.qywh {
	width: 100%;
	height: 598px;
	background-color: rgba(248, 248, 248, 1);
}

.qywh h1 {
	color: rgba(68, 68, 68, 1);
	font-size: 32px;
	line-height: 134px;
	text-align: center;
}

.qywh-item {
	margin: 30px 200px;

	justify-content: space-between;
	display: flex;
}

.qywh-item1 {
	margin-right: 16px;
	position: relative;
	width: 288px;
	height: 348px;
	background-color: rgba(255, 255, 255, 1);
	box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
	border-radius: 10px;
}

.qywh-item1 span {
	position: absolute;
	left: 91px;
	top: 30px;
	display: inline-block;
	border-radius: 100%;
	width: 90px;
	height: 90px;
	background-color: rgba(52, 145, 229, 1);
}

.qywh-item1 span > img {
	border-radius: 100%;
	height: 100%;
	width: 100%;
}

.qywh-text {
	position: absolute;
	top: 138px;
	left: 91px;
	width: 100px;
	color: rgba(68, 68, 68, 1);
	font-size: 24px;
}

.qywh-text2 {
	position: absolute;
	top: 192px;
	left: 10px;
	text-align: center;
	color: rgba(68, 68, 68, 1);
	font-size: 18px;
	line-height: 28px;
}

.text2-2 {
	width: 91px;
	line-height: 30px;
	position: absolute;
	left: 92px;
}

/* 总裁寄语 */
.President {
	width: 100%px;
	height: 801px;
	background-image: url(../assets/img/摄图网_500941399_banner_企业文化背景（非企业商用）.png);
	background-size: 100% 801px;
	padding-top: 84px;
}

.President-title > p {
	font-size: 32px;
	font-family: PingFangSC-Semibold, PingFang SC;
	font-weight: 600;
	color: #ffffff;
	line-height: 45px;
	text-align: center;
}

.President-text {
	width: 1200px;
	height: 465px;
	margin: 55px auto;
	background: #ffffff;
	box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.3);
	border-radius: 10px;
}

.President-text > p {
	width: 1000px;
	height: 262px;
	font-size: 16px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #666666;
	line-height: 40px;
	margin: 0 auto;
	padding-top: 71px;
}

.President-text2 {
	height: 50px;
	font-size: 24px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #222222;
	line-height: 33px;
	text-align: center;
	margin-top: 42px;
	position: relative;
}
.President-text2 > span {
	position: absolute;
	left: 491px;
	top: 15px;
}
.President-text2 > img {
	position: absolute;
}
/* 发展历程 */
.DevelopmentHistory {
	width: 1920;
	height: 527px;
	background-image: url("../assets/img/fazhan_bg.png");
}
.DevelopmentHistory_cont {
	width: 1200px;
	margin: 0 auto;
}
.DevelopmentHistory_cont_img img {
	margin-top: 61px;
	width: 32px;
	height: 32px;
}
.DevelopmentHistory_cont_img1 {
	float: left;
}
.DevelopmentHistory_cont_img2 {
	float: right;
}
.DevelopmentHistory_cont > h1 {
	text-align: center;
	margin-top: 64px;

	font-size: 32px;
	font-family: PingFangSC-Semibold, PingFang SC;
	font-weight: 600;
	color: #444444;
	line-height: 45px;
}
.DevelopmentHistory_cont_ul {
	display: flex;
	margin-top: 12px;
	margin-left: 59px;
	justify-content: space-around;
}
.DevelopmentHistory_cont_ul ul p {
	font-size: 24px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	color: #23337f;
	line-height: 33px;
}

.DevelopmentHistory_cont_ul li {
	margin-top: 8px;
	list-style-type: none;

	font-size: 14px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #444444;
	line-height: 24px;
}
</style>
