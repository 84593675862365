<template>
  <!-- star -->

  <div class="youjiaopage_wrap">
    <nav2></nav2>
    <banner2 :bannerH="h1" :bannerP="p1" :bannerImg="Img1"></banner2>
    <!-- 面包屑 -->
    <!-- <div class="Crumbs">
      <div>当前位置：首页><span>金融</span></div>
    </div> -->
    <!-- 招聘信息 -->
    <div class="Recruitment" style="margin-bottom: 40px;">
      <div class="Recruitment_cont">
        <div
          class="Recruitment_cont_item1"
          v-for="(item, index) in arrList"
          :key="index"
          @click="onurl(index)"
        >
          <div class="Recruitment_cont_item1_p">
            <p>{{ item.p1 }}</p>
            <p>{{ item.p2 }}</p>
          </div>
          <div class="Recruitment_cont_item1_div1">
            <div>{{ item.div1 }}</div>
            <div>{{ item.div2 }}</div>
            <div>{{ item.div3 }}</div>
          </div>
          <div class="Recruitment_cont_item1_div1 div2">
            <div>
              {{ item.div4 }}<span class="div1_span">{{ item.span1 }}</span>
            </div>
            <div>{{ item.div5 }}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 翻页 -->
    <!-- <Pages></Pages> -->

    <!-- 申请职位 -->
    <applyFor></applyFor>

    <footer2></footer2>

    <!-- end -->
  </div>
</template>


<script>
import footer2 from "../components/footer.vue";
import banner2 from "../components/banner.vue";
// import Pages from "../components/recruitInfoPages.vue";
import nav2 from "../components/nav.vue";
import applyFor from "../components/applyFor.vue";
export default {
  name: "zhaopinxinxi",
  components: {
    footer2,
    nav2,
    banner2,
    // Pages,
    applyFor,
  },
  data() {
    return {
      h1: "安徽益全安全科技有限公司",
      Img1: require("../assets/img/keji/banner1.png"),
      p1: "益全-为了我们更好的安全",
      arrList: [
        {
          p1: "安全评价工程师",
          p2: "面议",
          div1: "学历要求：大专及以上",
          div2: "工作经验：1年以上（接受实习生）",
          div3: "招聘人数：3人",
          div4: "工作地点：",
          span1: "安徽合肥",
          div5: "",
        },
      ],
    };
  },
  methods: {
    onurl(index) {
      if (index < 2) {
        this.$router.push("/zhaopinxiangqing0_" + index);
      } else {
        alert("页面未创建无法跳转");
      }
    },
  },
};
</script>
<style scoped>
@import "../assets/css/recruit-info.css";
</style>


