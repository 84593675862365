<template>
	<div class="GroupOverview">
		<daohang></daohang>
		<!-- 服务方案 -->
		<div class="about-bg">
			<div class="about" id="about">
				<div class="about-top">
					<div class="about-top-l">
						<span style="font-weight: bolder;">4.可行性研究报告/项目建议书</span>
						<div>
							<p>
								<span style="font-size: 16px;line-height: 40px;">
									<strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;定义</strong>：可行性研究报告，简称可研报告，是从事一种经济活动（投资）之前，通过全面的调查研究，确定有利和不利的因素、项目是否可行，估计成功率大小、经济效益和社会效果程度，为决策者和主管机关审批部门（发改委、经贸局、经信局等）的上报文件。
								</span>
							</p>
							<p>
								<span style="font-size: 16px;line-height: 40px;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;项目建议书（又称项目立项申请书或立项申请报告）由项目筹建单位或项目法人根据国民经济的发展、国家和地方中长期规划、产业政策、生产力布局、国内外市场、所在地的内外部条件，就某一具体新建、扩建项目提出的项目的建议文件。用于投资建设立项报批文件。</span>
							</p>
							<p>
								<span style="font-size: 16px;line-height: 40px;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;实施阶段：新、改、扩建设项目立项报批前</span>
							</p>
							<p>
								<span style="font-size: 16px;line-height: 40px;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;评价周期：20个工作日</span>
							</p>
							<p>
								<span style="font-size: 16px;line-height: 40px;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;所需基本资料：表4-1</span>
							</p>
							<p style="position: relative;left: 35%;">
								<span style="font-size: 18px;line-height: 40px;">
									<strong style="font-weight: bolder;">表4-1 可行性研究报告/项目建议书所需基本资料清单</strong>
								</span>
							</p>
							<table 
								style="width: 800px; height: 69px; border: 1px solid #000;margin: 0 auto; border-collapse: collapse;" 
								width="470.0" 
								cellspacing="0" 
								cellpadding="0">
								<tbody>
									<tr style="height: 20px;">
										<td style="width: 105.359px; border-width: 1px; border-color: #000; 
										padding: 0px 5px; height: 20px; text-align: center; border-bottom: 1px solid #000; 
										border-right: #000; border: 1px solid #000;" valign="top">
											<div>
												<span style="font-size: 16px;">序号</span>
											</div>
										</td>
										<td style="width: 730.641px; border-width: 1px; border-color: #000; 
										padding: 0px 5px; text-align: center; height: 20px; border-bottom: 1px solid #000; 
										border-right: #000; border: 1px solid #000;" valign="top">
											<div >
												<span style="font-size: 16px;">必要资料</span>
											</div>
										</td>
									</tr>
									<tr style="text-align: center;">
										<td style="width: 105.359px; border-width: 1px; border-color: #000; 
										padding: 0px 5px; height: 20px; border-bottom: 1px solid #000; 
										border-right: #000; border: 1px solid #000;" valign="top">
											<div>
												<span style="font-size: 16px;">1</span>
											</div>
										</td>
										<td style="width: 730.641px; border-width: 1px; border-color: #000; 
										padding: 0px 5px; height: 20px; border-bottom: 1px solid #000; 
										border-right: #000; border: 1px solid #000;" valign="top">
											<div>
												<span style="font-size: 16px; float: left;">企业简介、建设规模、投资关系、人员体系架构、定员等</span>
											</div>
										</td>
									</tr>
									<tr style="text-align: center;">
										<td style="width: 105.359px; border-width: 1px; border-color: #000; 
										padding: 0px 5px; height: 20px; border-bottom: 1px solid #000; 
										border-right: #000; border: 1px solid #000;" valign="top">
											<div>
												<span style="font-size: 16px;">2</span>
											</div>
										</td>
										<td style="width: 730.641px; border-width: 1px; border-color: #000; 
										padding: 0px 5px; height: 20px; border-bottom: 1px solid #000; 
										border-right: #000; border: 1px solid #000;" valign="top">
											<div>
												<span style="ont-size: 16px; float: left;">治安保卫人员、保管员任命及证书</span>
											</div>
										</td>
									</tr>
									<tr style="text-align: center;">
										<td style="width: 105.359px; border-width: 1px; border-color: #000; 
										padding: 0px 5px; height: 20px; border-bottom: 1px solid #000; 
										border-right: #000; border: 1px solid #000;" valign="top">
											<div>
												<span style="font-kerning: none; font-size: 16px;">3</span>
											</div>
										</td>
										<td style="width: 730.641px; border-width: 1x; border-color: #000; 
										padding: 0px 5px; height: 20px; border-bottom: 1px solid #000; 
										border-right: #000; border: 1px solid #000;" valign="top">
											<div>
												<span style="ffont-size: 16px; float: left;">出入库、交接班、电子巡查、防盗、防抢、防破坏进行定期演练。演练、人员培训记录</span>
											</div>
										</td>
									</tr>
									<tr style="text-align: center;">
										<td style="width: 105.359px; border-width: 1px; border-color: #000; 
										padding: 0px 5px; height: 20px; border-bottom: 1px solid #000; 
										border-right: #000; border: 1px solid #000;" valign="top">
											<div>
												<span style="font-kerning: none; font-size: 16px;">4</span>
											</div>
										</td>
										<td style="width: 730.641px; border-width: 1x; border-color: #000; 
										padding: 0px 5px; height: 20px; border-bottom: 1px solid #000; 
										border-right: #000; border: 1px solid #000;" valign="top">
											<div>
												<span style="ffont-size: 16px; float: left;">其他</span>
											</div>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 底部 -->
		<footer2></footer2>
	</div>
</template>

<script>
import daohang from "../components/nav.vue";
import footer2 from "../components/footer.vue";
export default {
	name: "App",
	data() {
		return {
			h1: "集团概况",
			P1: "欢迎加入我们",
			Img1: require("../assets/img/bannerjituan.png"),
		};
	},
	components: {
		daohang,
		footer2,
	},
	created() {
		this.$nextTick(() => {
			this.toLocal();
		});
	},
	mounted() {
		let _this = this;
		_this.$nextTick(function () {
			window.addEventListener("scroll", _this.handleScroll);
		});
	},
	methods: {
		toLocal() {
			//查找存储的锚点id
			let Id = localStorage.getItem("toId");
			let toElement = document.getElementById(Id);
			//锚点存在跳转
			if (Id) {
				toElement.scrollIntoView();
				localStorage.setItem("toId", "");
			}
		},
	},
};
</script>

<style scoped>
.GroupOverview {
	width: 100vw;
	margin: 0 auto;
}
.about {
	height: 900px;
	position: relative;
	margin: 0px 200px;
}

.about-bg {
	background-image: url("../assets/img/蒙版.png");
}

.about-top {
	position: relative;
	display: flex;
}

.about-top-l {
	position: absolute;
	left: 20px;
	top: 85px;
	width: 100%;
	height: 442px;
}

.about-top-l > span {
	font-size: 32px;
}

.about-top-l > p {
	font-size: 16px;
	line-height: 40px;
}

.img-yinying {
	position: absolute;
	top: 58px;
	left: -17px;
	width: 17px;
	height: 408px;
	background: #f8f8f8;
}

.about-top-r {
	position: absolute;
	right: -110px;
}

.about-top-r img {
	width: 487px;
	height: 517px;
}

.about-down {
	position: absolute;
	bottom: 20px;
}

.item {
	top: 236px;
	position: relative;
}

.item2 {
	top: 158px;
	left: 328px;
	position: relative;
}

.item3 {
	top: 79px;
	left: 645px;
	position: relative;
}

.item4 {
	left: 1049px;
	position: relative;
}

.item-1 {
	color: rgba(35, 51, 127, 1);
	font-size: 60px;
}

.item-2 {
	position: absolute;
	color: rgba(35, 51, 127, 1);
	font-size: 16.8px;
}

.item-3 {
	position: absolute;
	left: 88px;
	top: 41px;
	color: rgba(102, 102, 102, 1);
	font-size: 20px;
	width: 100px;
}

.item2-3 {
	position: relative;
	color: rgba(102, 102, 102, 1);
	left: 18px;
	font-size: 20px;
}

.item3-3 {
	position: relative;
	color: rgba(102, 102, 102, 1);
	left: 18px;
	font-size: 20px;
}

.item4-3 {
	position: relative;
	color: rgba(102, 102, 102, 1);
	left: 18px;
	font-size: 20px;
}

.qywh {
	width: 100%;
	height: 598px;
	background-color: rgba(248, 248, 248, 1);
}

.qywh h1 {
	color: rgba(68, 68, 68, 1);
	font-size: 32px;
	line-height: 134px;
	text-align: center;
}

.qywh-item {
	margin: 30px 200px;

	justify-content: space-between;
	display: flex;
}

.qywh-item1 {
	margin-right: 16px;
	position: relative;
	width: 288px;
	height: 348px;
	background-color: rgba(255, 255, 255, 1);
	box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
	border-radius: 10px;
}

.qywh-item1 span {
	position: absolute;
	left: 91px;
	top: 30px;
	display: inline-block;
	border-radius: 100%;
	width: 90px;
	height: 90px;
	background-color: rgba(52, 145, 229, 1);
}

.qywh-item1 span > img {
	border-radius: 100%;
	height: 100%;
	width: 100%;
}

.qywh-text {
	position: absolute;
	top: 138px;
	left: 91px;
	width: 100px;
	color: rgba(68, 68, 68, 1);
	font-size: 24px;
}

.qywh-text2 {
	position: absolute;
	top: 192px;
	left: 10px;
	text-align: center;
	color: rgba(68, 68, 68, 1);
	font-size: 18px;
	line-height: 28px;
}

.text2-2 {
	width: 91px;
	line-height: 30px;
	position: absolute;
	left: 92px;
}

/* 总裁寄语 */
.President {
	width: 100%px;
	height: 801px;
	background-image: url(../assets/img/摄图网_500941399_banner_企业文化背景（非企业商用）.png);
	background-size: 100% 801px;
	padding-top: 84px;
}

.President-title > p {
	font-size: 32px;
	font-family: PingFangSC-Semibold, PingFang SC;
	font-weight: 600;
	color: #ffffff;
	line-height: 45px;
	text-align: center;
}

.President-text {
	width: 1200px;
	height: 465px;
	margin: 55px auto;
	background: #ffffff;
	box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.3);
	border-radius: 10px;
}

.President-text > p {
	width: 1000px;
	height: 262px;
	font-size: 16px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #666666;
	line-height: 40px;
	margin: 0 auto;
	padding-top: 71px;
}

.President-text2 {
	height: 50px;
	font-size: 24px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #222222;
	line-height: 33px;
	text-align: center;
	margin-top: 42px;
	position: relative;
}
.President-text2 > span {
	position: absolute;
	left: 491px;
	top: 15px;
}
.President-text2 > img {
	position: absolute;
}
/* 发展历程 */
.DevelopmentHistory {
	width: 1920;
	height: 527px;
	background-image: url("../assets/img/fazhan_bg.png");
}
.DevelopmentHistory_cont {
	width: 1200px;
	margin: 0 auto;
}
.DevelopmentHistory_cont_img img {
	margin-top: 61px;
	width: 32px;
	height: 32px;
}
.DevelopmentHistory_cont_img1 {
	float: left;
}
.DevelopmentHistory_cont_img2 {
	float: right;
}
.DevelopmentHistory_cont > h1 {
	text-align: center;
	margin-top: 64px;

	font-size: 32px;
	font-family: PingFangSC-Semibold, PingFang SC;
	font-weight: 600;
	color: #444444;
	line-height: 45px;
}
.DevelopmentHistory_cont_ul {
	display: flex;
	margin-top: 12px;
	margin-left: 59px;
	justify-content: space-around;
}
.DevelopmentHistory_cont_ul ul p {
	font-size: 24px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	color: #23337f;
	line-height: 33px;
}

.DevelopmentHistory_cont_ul li {
	margin-top: 8px;
	list-style-type: none;

	font-size: 14px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #444444;
	line-height: 24px;
}
</style>
