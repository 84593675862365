import Vue from "vue";
import VueRouter from "vue-router";
import index from "../views/index.vue";
import form1 from "../views/form1.vue";
import form2 from "../views/form2.vue";
import form3 from "../views/form3.vue";
import jituangaikuo from "../views/jituangaikuo.vue";
import jituangaikuo1 from "../views/jituangaikuo1.vue";
import jituangaikuo2 from "../views/jituangaikuo2.vue";
import jituangaikuo3 from "../views/jituangaikuo3.vue";
import jituangaikuo4 from "../views/jituangaikuo4.vue";
import jituangaikuo5 from "../views/jituangaikuo5.vue";
import jituangaikuo6 from "../views/jituangaikuo6.vue";
import jituangaikuo7 from "../views/jituangaikuo7.vue";
import jituangaikuo8 from "../views/jituangaikuo8.vue";
import news from "../views/news.vue";
import GroupNews from "../views/News/GroupNews.vue";
import MediaCoverage from "../views/News/MediaCoverage.vue";
import TradeNews from "../views/News/TradeNews.vue";
import newsDetail from "../views/newsxiangqing.vue";
import newsDetail1 from "../views/NewsDetals/newsxiangqing1.vue";
import newsDetail2 from "../views/NewsDetals/newsxiangqing2.vue";
import MediaCoverage0 from "../views/NewsDetals/MediaCoverage0.vue";
import MediaCoverage1 from "../views/NewsDetals/MediaCoverage1.vue";
import TradeNews0 from "../views/NewsDetals/TradeNews0.vue";
import TradeNews1 from "../views/NewsDetals/TradeNews1.vue";
import zhaopinxinxi0 from "../views/zhaopinxinxi0.vue";
import zhaopinxinxi1 from "../views/Recruit-info/zhaopinxinxi1.vue";
import lianxi from "../views/lianxiwomen.vue";
import zhaopinxiangqing0_1 from "../views/Recruitment-details/zhaopinxiangqing0-1.vue";
import zhaopinxiangqing0_0 from "../views/Recruitment-details/zhaopinxiangqing0-0.vue";
import zhaopinxiangqing1_0 from "../views/Recruitment-details/zhaopinxiangqing1-0.vue";
import zhaopinxiangqing1_1 from "../views/Recruitment-details/zhaopinxiangqing1-1.vue";

// 报错解决
// 获取原型对象push函数
const originalPush = VueRouter.prototype.push;

// 获取原型对象replace函数
const originalReplace = VueRouter.prototype.replace;

// 修改原型对象中的push函数
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch((err) => err);
};

// 修改原型对象中的replace函数
VueRouter.prototype.replace = function replace(location) {
	return originalReplace.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		name: "index",
		component: index,
		meta: {
			title: "首页",
		},
	},
	{
		path: "/form1",
		name: "form1",
		component: form1,
		meta: {
			title: "公司介绍",
		},
	},
	{
		path: "/form2",
		name: "form2",
		component: form2,
		meta: {
			title: "业务范围",
		},
	},
	{
		path: "/form3",
		name: "form3",
		component: form3,
		meta: {
			title: "服务方案",
		},
	},
	{
		path: "/jituangaikuo",
		name: "jituangaikuo",
		component: jituangaikuo,
		meta: {
			title: "收费标准",
		},
	},
	{
		path: "/jituangaikuo1",
		name: "jituangaikuo1",
		component: jituangaikuo1,
		meta: {
			title: "服务方案-安全设施“三同时",
		},
	},
	{
		path: "/jituangaikuo2",
		name: "jituangaikuo2",
		component: jituangaikuo2,
		meta: {
			title: "服务方案-安全现状评价/评估、安全风险评估",
		},
	},
	{
		path: "/jituangaikuo3",
		name: "jituangaikuo3",
		component: jituangaikuo3,
		meta: {
			title: "服务方案-专项安全评价",
		},
	},
	{
		path: "/jituangaikuo4",
		name: "jituangaikuo4",
		component: jituangaikuo4,
		meta: {
			title: "服务方案-可行性研究报告/项目建议书",
		},
	},
	{
		path: "/jituangaikuo5",
		name: "jituangaikuo5",
		component: jituangaikuo5,
		meta: {
			title: "服务方案-生产安全事故应急预案",
		},
	},
	{
		path: "/jituangaikuo6",
		name: "jituangaikuo6",
		component: jituangaikuo6,
		meta: {
			title: "服务方案-安全生产标准化",
		},
	},
	{
		path: "/jituangaikuo7",
		name: "jituangaikuo7",
		component: jituangaikuo7,
		meta: {
			title: "服务方案-安全生产风险分级管控和隐患排查治理双重预防机制建设",
		},
	},
	{
		path: "/jituangaikuo8",
		name: "jituangaikuo8",
		component: jituangaikuo8,
		meta: {
			title: "服务方案-安全管家服务类",
		},
	},
	{
		path: "/xinwen",
		name: "xinwen",
		component: news,
		children: [
			{
				path: "",
				component: GroupNews,
				name: "GroupNews",
				meta: {
					title: "集团新闻",
				},
			},
			{
				path: "MediaCoverage",
				component: MediaCoverage,
				name: "MediaCoverage",
				meta: {
					title: "媒体报道",
				},
			},
			{
				path: "TradeNews",
				name: "TradeNews",
				component: TradeNews,
				meta: {
					title: "行业资讯",
				},
			},
		],
		meta: {
			title: "动态",
		},
	},
	{
		path: "/newsxiangqing0",
		name: "newsDetal",
		component: newsDetail,
		// meta: {
		// 	title: "新闻详情",
		// },
	},
	{
		path: "/newsxiangqing1",
		name: "newsDetal1",
		component: newsDetail1,
		// meta: {
		// 	title: "新闻详情1",
		// },
	},
	{
		path: "/newsxiangqing2",
		name: "newsDetal2",
		component: newsDetail2,
		// meta: {
		// 	title: "新闻详情2",
		// },
	},
	{
		path: "/MediaCoverage0",
		name: "MediaCoverage0",
		component: MediaCoverage0,
		// meta: {
		// 	title: "媒体报道0",
		// },
	},
	{
		path: "/MediaCoverage1",
		name: "MediaCoverage1",
		component: MediaCoverage1,
		// meta: {
		// 	title: "媒体报道1",
		// },
	},
	{
		path: "/TradeNews0",
		name: "TradeNews0",
		component: TradeNews0,
		// meta: {
		// 	title: "行业资讯0",
		// },
	},
	{
		path: "/TradeNews1",
		name: "TradeNews1",
		component: TradeNews1,
		// meta: {
		// 	title: "行业资讯1",
		// },
	},
	{
		path: "/zhaopinxinxi0",
		name: "zhaopinxinxi0",
		component: zhaopinxinxi0,
		meta: {
			title: "招聘信息",
		},
	},
	{
		path: "/zhaopinxinxi1",
		name: "zhaopinxinxi1",
		component: zhaopinxinxi1,
		meta: {
			title: "招聘信息",
		},
	},
	{
		path: "/lianxi",
		name: "lianxi",
		component: lianxi,
		meta: {
			title: "联系我们",
		},
	},
	{
		path: "/zhaopinxiangqing0_0",
		name: "zhaopinxiangqing0_0",
		component: zhaopinxiangqing0_0,
		meta: {
			title: "招聘详情",
		},
	},
	{
		path: "/zhaopinxiangqing0_1",
		name: "zhaopinxiangqing0_1",
		component: zhaopinxiangqing0_1,
		meta: {
			title: "招聘详情0-1",
		},
	},
	{
		path: "/zhaopinxiangqing1_0",
		name: "zhaopinxiangqing1_0",
		component: zhaopinxiangqing1_0,
		meta: {
			title: "招聘详情1-0",
		},
	},
	{
		path: "/zhaopinxiangqing1_1",
		name: "zhaopinxiangqing1_1",
		component: zhaopinxiangqing1_1,
		meta: {
			title: "招聘详情1-1",
		},
	},
];

const router = new VueRouter({
	mode: 'history',
	routes,
	// 跳转页面后 回到顶部
	scrollBehavior() {
		return {
			x: 0,
			y: 0,
		};
	},
});

export default router;

//title
const defaultTitle = "默认 title";
router.beforeEach((to, from, next) => {
	document.title = to.meta.title ? to.meta.title : defaultTitle;
	next();
});
