<template>
	<div class="GroupOverview">
		<daohang></daohang>
		<!-- 服务方案 -->
		<div class="about-bg">
			<div class="about" id="about">
				<div class="about-top">
					<div class="about-top-l">
						<span style="font-weight: bolder;">7.安全生产风险分级管控和隐患排查治理双重预防机制建设（双重预防机制）</span>
						<p>
							<strong style="font-weight: bolder;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;法规依据：</strong>《安全生产法》、《关于实施遏制重特大事故工作指南构建安全风险分级管控和隐患排查治理双重预防机制的意见》国务院安委办2016年10月9日印发、《安徽省人民政府安委会办公室关于印发〈构建“六项机制”强化安全生产风险管控工作实施细则〉的通知》（皖安办〔2017〕19 号）
						</p>
						<div>
							<p>
								<span style="font-size: 16px;line-height: 40px;">
									<strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;定义</strong>：安全风险分级管控制度和隐患排查治理机制，合称双重预防机制；安全风险分级管控，就是我们日常工作中的风险管理，包括危险源辨识、风险评价分级、风险管控，即辨识风险点有哪些危险物质及能量，在什么情况下可能发生什么事故，全面排查风险点的现有管控措施是否完好，运用风险评价准则对风险点的风险进行评价分级，然后由不同层级的人员对风险进行管控，保证风险点的安全管控措施完好。隐患排查治理就是对风险点的管控措施通过隐患排查等方式进行全面管控，及时发现风险点管控措施潜在的隐患，及时对隐患进行治理。
								</span>
							</p>
							<p>
								<span style="font-size: 16px;line-height: 40px;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;评价周期：30个工作日</span>
							</p>
							<p>
								<span style="font-size: 16px;line-height: 40px;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;工作程序：前期准备、危害辨识与风险识别、风险评估、风险分级、风险管控、隐患排查、隐患治理、效果评估、持续改进等方面。</span>
							</p>
							<p>
								<span style="font-size: 16px;line-height: 40px; font-weight: bolder;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;注：安全生产风险分级管控和隐患排查治理双重预防机制建设流程图</span>
							</p>
							<div style="margin-bottom: 20px; text-align: center;">
								<img src="../assets/img/fuwufangan/图片7.png" alt=""/>
							</div>
							<p>
								<span style="font-size: 16px;line-height: 40px; font-weight: bolder;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;工作重点：风险评估、分级与管控</span>
							</p>
							<p>
								<span style="font-size: 16px;line-height: 40px;"><strong style="font-weight: bolder;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;目视化成果输出：</strong>（一单、一图、一表、一卡、一账）企业重点风险管控空间分布图、安全风险分级管控一览表、岗位安全风险点告知卡、事故隐患排查治理工作台账</span>
							</p>
							<p>
								<span style="font-size: 16px;line-height: 40px;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;“一单”即企业主要风险点、危险源清单，采用风险矩阵法（LS）、作业条件危险性分析法（LEC）等评估方法进行风险评估，确定危险源可导致不同事故类型的风险等级。风险等级从高到低划分为重大风险、较大风险、一般风险和低风 险四个等级，分别用红、橙、黄、蓝四种颜色代表。所选用风险评估 方法的风险等级划分结果应合理对应到重大风险、较大风险、一般风 险和低风险四个等级清单的主要项目包括风险点、所在位置或 设备号、潜在事故类型、风险等级、预警色、管控措施、责任部门、责任人等。</span>
							</p>
							<div style="margin-bottom: 20px; text-align: center;">
								<img src="../assets/img/fuwufangan/图片2.png" alt=""/>
							</div>
							<p>
								<span style="font-size: 16px;line-height: 40px;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;“一图”：即企业重点风险管控空间分布图。包括企业风险管控空间分布图，车间风险管控空间分布图，岗位风险告知卡。一图的主要内容，包括主要危险因素名称、部位名称、风险等级、主要防范措施、应急处置措施、易发生的事故类型、责任人等，并根据安全风险等级，分别以红、橙、黄、蓝四色标绘，并在显著位置张贴告知。</span>
							</p>
							<div style="margin-bottom: 20px; text-align: center;">
								<img src="../assets/img/fuwufangan/图片3.png" alt=""/>
							</div>
							<p>
								<span style="font-size: 16px;line-height: 40px;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;“一表”：即“安全风险分级管控一览表”。“一表”的内容，包括安全风险名称、所在部门岗位、风险等级、危险因素、可能后果及原因分析，具体的防范措施、应急措施，具体责任部门及责任人等，并按照风险等级分级建档。</span>
							</p>
							<div style="margin-bottom: 20px; text-align: center;">
								<img src="../assets/img/fuwufangan/图片4.png" alt=""/>
							</div>
							<p>
								<span style="font-size: 16px;line-height: 40px;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;“一卡”：即岗位安全风险点告知卡，包含岗位名称、岗位存在的危 害有害因素、后果、防范措施、应急处置措施、安全操作要点及应急 电话等内容。</span>
							</p>
							<div style="margin-bottom: 20px; text-align: center;">
								<img src="../assets/img/fuwufangan/图片5.png" alt=""/>
							</div>
							<p>
								<span style="font-size: 16px;line-height: 40px;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;“一账”：即事故隐患排查治理工作台账，根据辨识出的危险因素，分类、评价并划分安全风险等级，分析、预判可能的后果和影响因素，制定事故隐患排查手册；建立公司级、车间级、岗位级隐患排查治理工作台账；据此事故隐患排查手册，实施月排查（企业级）、周排查（车间级）、日排查（岗位级），建立隐患整改督办验收制度，明确排查、整改、验收等环节的责任人员，建立全员、全岗位、全过程的隐患排查治理机制。</span>
							</p>
							<div style="margin-bottom: 20px; text-align: center;">
								<img src="../assets/img/fuwufangan/图片6.png" alt=""/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 底部 -->
		<footer2></footer2>
	</div>
</template>

<script>
import daohang from "../components/nav.vue";
import footer2 from "../components/footer.vue";
export default {
	name: "App",
	data() {
		return {
			h1: "集团概况",
			P1: "欢迎加入我们",
			Img1: require("../assets/img/bannerjituan.png"),
		};
	},
	components: {
		daohang,
		footer2,
	},
	created() {
		this.$nextTick(() => {
			this.toLocal();
		});
	},
	mounted() {
		let _this = this;
		_this.$nextTick(function () {
			window.addEventListener("scroll", _this.handleScroll);
		});
	},
	methods: {
		toLocal() {
			//查找存储的锚点id
			let Id = localStorage.getItem("toId");
			let toElement = document.getElementById(Id);
			//锚点存在跳转
			if (Id) {
				toElement.scrollIntoView();
				localStorage.setItem("toId", "");
			}
		},
	},
};
</script>

<style scoped>
.GroupOverview {
	width: 100vw;
	margin: 0 auto;
}
.about {
	height: 4050px;
	position: relative;
	margin: 0px 200px;
}

.about-bg {
	background-image: url("../assets/img/蒙版.png");
}

.about-top {
	position: relative;
	display: flex;
}

.about-top-l {
	position: absolute;
	left: 20px;
	top: 85px;
	width: 100%;
	height: 442px;
}

.about-top-l > span {
	font-size: 32px;
}

.about-top-l > p {
	font-size: 16px;
	line-height: 40px;
}

.img-yinying {
	position: absolute;
	top: 58px;
	left: -17px;
	width: 17px;
	height: 408px;
	background: #f8f8f8;
}

.about-top-r {
	position: absolute;
	right: -110px;
}

.about-top-r img {
	width: 487px;
	height: 517px;
}

.about-down {
	position: absolute;
	bottom: 20px;
}

.item {
	top: 236px;
	position: relative;
}

.item2 {
	top: 158px;
	left: 328px;
	position: relative;
}

.item3 {
	top: 79px;
	left: 645px;
	position: relative;
}

.item4 {
	left: 1049px;
	position: relative;
}

.item-1 {
	color: rgba(35, 51, 127, 1);
	font-size: 60px;
}

.item-2 {
	position: absolute;
	color: rgba(35, 51, 127, 1);
	font-size: 16.8px;
}

.item-3 {
	position: absolute;
	left: 88px;
	top: 41px;
	color: rgba(102, 102, 102, 1);
	font-size: 20px;
	width: 100px;
}

.item2-3 {
	position: relative;
	color: rgba(102, 102, 102, 1);
	left: 18px;
	font-size: 20px;
}

.item3-3 {
	position: relative;
	color: rgba(102, 102, 102, 1);
	left: 18px;
	font-size: 20px;
}

.item4-3 {
	position: relative;
	color: rgba(102, 102, 102, 1);
	left: 18px;
	font-size: 20px;
}

.qywh {
	width: 100%;
	height: 598px;
	background-color: rgba(248, 248, 248, 1);
}

.qywh h1 {
	color: rgba(68, 68, 68, 1);
	font-size: 32px;
	line-height: 134px;
	text-align: center;
}

.qywh-item {
	margin: 30px 200px;

	justify-content: space-between;
	display: flex;
}

.qywh-item1 {
	margin-right: 16px;
	position: relative;
	width: 288px;
	height: 348px;
	background-color: rgba(255, 255, 255, 1);
	box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
	border-radius: 10px;
}

.qywh-item1 span {
	position: absolute;
	left: 91px;
	top: 30px;
	display: inline-block;
	border-radius: 100%;
	width: 90px;
	height: 90px;
	background-color: rgba(52, 145, 229, 1);
}

.qywh-item1 span > img {
	border-radius: 100%;
	height: 100%;
	width: 100%;
}

.qywh-text {
	position: absolute;
	top: 138px;
	left: 91px;
	width: 100px;
	color: rgba(68, 68, 68, 1);
	font-size: 24px;
}

.qywh-text2 {
	position: absolute;
	top: 192px;
	left: 10px;
	text-align: center;
	color: rgba(68, 68, 68, 1);
	font-size: 18px;
	line-height: 28px;
}

.text2-2 {
	width: 91px;
	line-height: 30px;
	position: absolute;
	left: 92px;
}

/* 总裁寄语 */
.President {
	width: 100%px;
	height: 801px;
	background-image: url(../assets/img/摄图网_500941399_banner_企业文化背景（非企业商用）.png);
	background-size: 100% 801px;
	padding-top: 84px;
}

.President-title > p {
	font-size: 32px;
	font-family: PingFangSC-Semibold, PingFang SC;
	font-weight: 600;
	color: #ffffff;
	line-height: 45px;
	text-align: center;
}

.President-text {
	width: 1200px;
	height: 465px;
	margin: 55px auto;
	background: #ffffff;
	box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.3);
	border-radius: 10px;
}

.President-text > p {
	width: 1000px;
	height: 262px;
	font-size: 16px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #666666;
	line-height: 40px;
	margin: 0 auto;
	padding-top: 71px;
}

.President-text2 {
	height: 50px;
	font-size: 24px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #222222;
	line-height: 33px;
	text-align: center;
	margin-top: 42px;
	position: relative;
}
.President-text2 > span {
	position: absolute;
	left: 491px;
	top: 15px;
}
.President-text2 > img {
	position: absolute;
}
/* 发展历程 */
.DevelopmentHistory {
	width: 1920;
	height: 527px;
	background-image: url("../assets/img/fazhan_bg.png");
}
.DevelopmentHistory_cont {
	width: 1200px;
	margin: 0 auto;
}
.DevelopmentHistory_cont_img img {
	margin-top: 61px;
	width: 32px;
	height: 32px;
}
.DevelopmentHistory_cont_img1 {
	float: left;
}
.DevelopmentHistory_cont_img2 {
	float: right;
}
.DevelopmentHistory_cont > h1 {
	text-align: center;
	margin-top: 64px;

	font-size: 32px;
	font-family: PingFangSC-Semibold, PingFang SC;
	font-weight: 600;
	color: #444444;
	line-height: 45px;
}
.DevelopmentHistory_cont_ul {
	display: flex;
	margin-top: 12px;
	margin-left: 59px;
	justify-content: space-around;
}
.DevelopmentHistory_cont_ul ul p {
	font-size: 24px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	color: #23337f;
	line-height: 33px;
}

.DevelopmentHistory_cont_ul li {
	margin-top: 8px;
	list-style-type: none;

	font-size: 14px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #444444;
	line-height: 24px;
}
</style>
