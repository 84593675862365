<template>
	<div class="GroupOverview">
		<daohang></daohang>
		<!-- 收费标准 -->
		<div class="about-bg">
			<div class="about" id="about">
				<div class="about-top">
					<div class="about-top-l">
						<span style="font-weight: bolder;">收费标准</span>
						<div>
							<p>
								1.参照各省安全评价行业自律收费标准，根据具体项目调整系数，制订项目费用。
							</p>
							<p>
								2.在开展安全评价技术服务时，根据项目投资额、评价类型、服务行业、复 杂程度、所担风险等因素， 并区分一般评价项目和部分专项现状评价项目， 确定收费标准。
							</p>
							<p>
								具体如下：
							</p>
							<p>
								一般评价项目收费标准计算
							</p>
							<p>
								1.计算公式
							</p>
							<P>
								收费标准是在收费基准上，综合考量评价类型、服务行业和工程复杂程度等因素计算得出，即：收费标准=收费基准×评价类型调整系数×行业调整系数×工程复杂程度调整系数
							</P>
							<p>
								2.收费基准
							</p>
							<p>
								评价项目计算基数
							</p>
							<table
								style="width: 800px; height: 69px; border: 1px solid #000;margin-bottom: 35px; border-collapse: collapse;" 
								width="470.0" 
								cellspacing="0" 
								cellpadding="0">
								<tbody>
									<tr style="text-align: center;">
										<td style="width: 650.359px; border-width: 1px; border-color: #000; 
										padding: 0px 5px; height: 80px; border-bottom: 1px solid #000; text-align: center;
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="font-size: 16px;">投资额</span>
											</div>
										</td>
										<td style="width: 650.359px; border-width: 1px; border-color: #000; 
										padding: 0px 5px; height: 80px; border-bottom: 1px solid #000; text-align: center; 
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="font-size: 16px;">500万元以下</span>
											</div>
										</td>
										<td style="width: 650.359px; border-width: 1px; border-color: #000;
										padding: 0px 5px; height: 80px; border-bottom: 1px solid #000; text-align: center;
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="font-size: 16px;">500～1000万元</span>
											</div>
										</td>
										<td style="width: 650.359px; border-width: 1px; border-color: #000;
										padding: 0px 5px; height: 80px; border-bottom: 1px solid #000; text-align: center;
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="font-size: 16px;">1000～2000万元</span>
											</div>
										</td>
										<td style="width: 650.359px; border-width: 1px; border-color: #000;
										padding: 0px 5px; height: 80px; border-bottom: 1px solid #000; text-align: center;
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="font-size: 16px;">2000～5000万元</span>
											</div>
										</td>
										<td style="width: 650.359px; border-width: 1px; border-color: #000;
										padding: 0px 5px; height: 80px; border-bottom: 1px solid #000; text-align: center;
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="font-size: 16px;">0.5～1亿元</span>
											</div>
										</td>
										<td style="width: 650.359px; border-width: 1px; border-color: #000;
										padding: 0px 5px; height: 80px; border-bottom: 1px solid #000; text-align: center;
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="font-size: 16px;">1亿元以上</span>
											</div>
										</td>
									</tr>
									
									<tr style="text-align: center;">
										<td style="width: 650.359px; border-width: 1px; border-color: #000; 
										padding: 0px 5px; height: 80px; border-bottom: 1px solid #000; text-align: center;
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="font-size: 16px;">计算基数</span>
											</div>
										</td>
										<td style="width: 650.359px; border-width: 1px; border-color: #000; 
										padding: 0px 5px; height: 80px; border-bottom: 1px solid #000; text-align: center;
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="font-size: 16px;">0.8～1万元</span>
											</div>
										</td>
										<td style="width: 650.359px; border-width: 1px; border-color: #000;
										padding: 0px 5px; height: 80px; border-bottom: 1px solid #000; text-align: center;
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="font-size: 16px;">1～2万元</span>
											</div>
										</td>
										<td style="width: 650.359px; border-width: 1px; border-color: #000;
										padding: 0px 5px; height: 80px; border-bottom: 1px solid #000; text-align: center;
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="font-size: 16px;">2～3万元</span>
											</div>
										</td>
										<td style="width: 650.359px; border-width: 1px; border-color: #000;
										padding: 0px 5px; height: 80px; border-bottom: 1px solid #000; text-align: center;
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="font-size: 16px;">3～4万元</span>
											</div>
										</td>
										<td style="width: 650.359px; border-width: 1px; border-color: #000;
										padding: 0px 5px; height: 80px; border-bottom: 1px solid #000; text-align: center;
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="font-size: 16px;">4～5万元</span>
											</div>
										</td>
										<td style="width: 650.359px; border-width: 1px; border-color: #000;
										padding: 0px 5px; height: 80px; border-bottom: 1px solid #000; text-align: center;
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="font-size: 16px;">5～10万元</span>
											</div>
										</td>
									</tr>
								</tbody>
							</table>
							
							<table
								style="width: 800px; height: 69px; border: 1px solid #000; border-collapse: collapse;" 
								width="470.0" 
								cellspacing="0" 
								cellpadding="0">
								<tbody>
									<tr style="text-align: center;">
										<td style="width: 650.359px; border-width: 1px; border-color: #000; 
										padding: 0px 5px; height: 80px; border-bottom: 1px solid #000; text-align: center;
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="font-size: 16px;">生产规模（人数）</span>
											</div>
										</td>
										<td style="width: 650.359px; border-width: 1px; border-color: #000; 
										padding: 0px 5px; height: 80px; border-bottom: 1px solid #000; text-align: center;
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="font-size: 16px;">20人以内</span>
											</div>
										</td>
										<td style="width: 650.359px; border-width: 1px; border-color: #000;
										padding: 0px 5px; height: 80px; border-bottom: 1px solid #000; text-align: center;
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="font-size: 16px;">20～50人</span>
											</div>
										</td>
										<td style="width: 650.359px; border-width: 1px; border-color: #000;
										padding: 0px 5px; height: 80px; border-bottom: 1px solid #000; text-align: center;
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="font-size: 16px;">50～100人</span>
											</div>
										</td>
										<td style="width: 650.359px; border-width: 1px; border-color: #000;
										padding: 0px 5px; height: 80px; border-bottom: 1px solid #000; text-align: center;
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="font-size: 16px;">100～500人</span>
											</div>
										</td>
										<td style="width: 650.359px; border-width: 1px; border-color: #000;
										padding: 0px 5px; height: 80px; border-bottom: 1px solid #000; text-align: center;
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="font-size: 16px;">500人以上</span>
											</div>
										</td>									
									</tr>
									
									<tr style="text-align: center;">
										<td style="width: 650.359px; border-width: 1px; border-color: #000; 
										padding: 0px 5px; height: 80px; border-bottom: 1px solid #000; text-align: center;
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="font-size: 16px;">计算基数</span>
											</div>
										</td>
										<td style="width: 650.359px; border-width: 1px; border-color: #000; 
										padding: 0px 5px; height: 80px; border-bottom: 1px solid #000; text-align: center;
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="font-size: 16px;">1～2万元</span>
											</div>
										</td>
										<td style="width: 650.359px; border-width: 1px; border-color: #000;
										padding: 0px 5px; height: 80px; border-bottom: 1px solid #000; text-align: center;
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="font-size: 16px;">2～3万元</span>
											</div>
										</td>
										<td style="width: 650.359px; border-width: 1px; border-color: #000;
										padding: 0px 5px; height: 80px; border-bottom: 1px solid #000; text-align: center;
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="font-size: 16px;">3～4万元</span>
											</div>
										</td>
										<td style="width: 650.359px; border-width: 1px; border-color: #000;
										padding: 0px 5px; height: 80px; border-bottom: 1px solid #000; text-align: center;
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="font-size: 16px;">4～5万元</span>
											</div>
										</td>
										<td style="width: 650.359px; border-width: 1px; border-color: #000;
										padding: 0px 5px; height: 80px; border-bottom: 1px solid #000; text-align: center;
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="font-size: 16px;">5～10万元</span>
											</div>
										</td>
									</tr>
								</tbody>
							</table>
							<p>咨询类项目计算基数</p>
							<p>主要根据服务企业基础条件（包含现场整改难度系数、有无安环人员配合、现场风险程度）等要素综合进行计算收费标准。</p>
							<table
								style="width: 800px; height: 69px; border: 1px solid #000; border-collapse: collapse;" 
								width="470.0" 
								cellspacing="0" 
								cellpadding="0">
								<tbody>
									<tr>
										<td style="width: 650.359px; border-width: 1px; border-color: #000; 
										padding: 0px 5px; height: 80px; border-bottom: 1px solid #000; text-align: center;
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="font-size: 16px;">安环架构</span>
											</div>
										</td>
										<td style="width: 650.359px; border-width: 1px; border-color: #000; 
										padding: 0px 5px; height: 80px; border-bottom: 1px solid #000; text-align: center;
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="font-size: 16px;">有安环架构及专职人员</span>
											</div>
										</td>
										<td style="width: 650.359px; border-width: 1px; border-color: #000;
										padding: 0px 5px; height: 80px; border-bottom: 1px solid #000; text-align: center;
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="font-size: 16px;">兼职人员</span>
											</div>
										</td>
										<td style="width: 650.359px; border-width: 1px; border-color: #000;
										padding: 0px 5px; height: 80px; border-bottom: 1px solid #000; text-align: center;
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="font-size: 16px;">无安环架构及人员</span>
											</div>
										</td>					
									</tr>
									
									<tr>
										<td style="width: 650.359px; border-width: 1px; border-color: #000; 
										padding: 0px 5px; height: 80px; border-bottom: 1px solid #000; text-align: center;
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="font-size: 16px;">计算基数（万元）</span>
											</div>
										</td>
										<td style="width: 650.359px; border-width: 1px; border-color: #000; 
										padding: 0px 5px; height: 80px; border-bottom: 1px solid #000; text-align: center;
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="font-size: 16px;">2.0</span>
											</div>
										</td>
										<td style="width: 650.359px; border-width: 1px; border-color: #000;
										padding: 0px 5px; height: 80px; border-bottom: 1px solid #000; text-align: center;
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="font-size: 16px;">2.5</span>
											</div>
										</td>	
										<td style="width: 650.359px; border-width: 1px; border-color: #000;
										padding: 0px 5px; height: 80px; border-bottom: 1px solid #000; text-align: center;
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="font-size: 16px;">3.0</span>
											</div>
										</td>
									</tr>
									
									<tr>
										<td style="width: 650.359px; border-width: 1px; border-color: #000; 
										padding: 0px 5px; height: 80px; border-bottom: 1px solid #000; text-align: center;
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="font-size: 16px;">现场风险程度（k2）</span>
											</div>
										</td>
										<td style="width: 650.359px; border-width: 1px; border-color: #000; 
										padding: 0px 5px; height: 80px; border-bottom: 1px solid #000; text-align: center;
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top" colspan="3">
											<div>
												<span style="font-size: 16px;">高风险：1.2；中风险：1.0；低风险：0.8</span>
											</div>
										</td>
									</tr>
									<tr>
										<td style="width: 650.359px; border-width: 1px; border-color: #000; 
										padding: 0px 5px; height: 80px; border-bottom: 1px solid #000; text-align: center;
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="font-size: 16px;">复杂程度系数（k3）</span>
											</div>
										</td>
										<td style="width: 650.359px; border-width: 1px; border-color: #000; 
										padding: 0px 5px; height: 80px; border-bottom: 1px solid #000; text-align: center;
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top" colspan="3">
											<div>
												<span style="font-size: 16px;">复杂：1.2；一般： 1.0；简单：0.8</span>
											</div>
										</td>
									</tr>
									<tr>
										<td style="width: 650.359px; border-width: 1px; border-color: #000; 
										padding: 0px 5px; height: 80px; border-bottom: 1px solid #000; text-align: center;
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="font-size: 16px;">计算方法</span>
											</div>
										</td>
										<td style="width: 650.359px; border-width: 1px; border-color: #000; 
										padding: 0px 5px; height: 80px; border-bottom: 1px solid #000; text-align: center;
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top" colspan="3">
											<div>
												<span style="font-size: 16px;">实际收费=基础费用*k1*k2*k3*报告类型调整系数</span>
											</div>
										</td>
									</tr>
								</tbody>
							</table>
							<p>3.报告类型调整系数</p>
							<table
								style="width: 800px; height: 69px; border: 1px solid #000; border-collapse: collapse;" 
								width="470.0" 
								cellspacing="0" 
								cellpadding="0">
								<tbody>
									<tr>
										<td style="width: 150.641px; border-width: 1px; border-color: #000; 
										padding: 0px 5px; text-align: center; height: 30px; border-bottom: 1px solid #000; 
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="font-size: 16px;">报告类型</span>
											</div>
										</td>
										<td style="width: 150.641px; border-width: 1px; border-color: #000; 
										padding: 0px 5px; text-align: center; height: 30px; border-bottom: 1px solid #000; 
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div >
												<span style="font-size: 16px;">调整系数</span>
											</div>
										</td>
									</tr>
									
									<tr>
										<td style="width: 150.359px; border-width: 1px; border-color: #000; 
										padding: 0px 5px; height: 30px; border-bottom: 1px solid #000; text-align: center;
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="font-size: 16px;">安全生产条件和设施综合分析报告</span>
											</div>
										</td>
										<td style="width: 150.359px; border-width: 1px; border-color: #000;
										padding: 0px 5px; height: 30px; border-bottom: 1px solid #000; text-align: center;
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="font-size: 16px;">0.8～1.0</span>
											</div>
										</td>
									</tr>
									<tr>
										<td style="width: 150.359px; border-width: 1px; border-color: #000; 
										padding: 0px 5px; height: 30px; border-bottom: 1px solid #000; text-align: center;
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="font-size: 16px;">安全预评价</span>
											</div>
										</td>
										<td style="width: 150.359px; border-width: 1px; border-color: #000; 
										padding: 0px 5px; height: 30px; border-bottom: 1px solid #000; text-align: center;
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="font-size: 16px;">1.0～1.3</span>
											</div>
										</td>
									</tr>
									<tr>
										<td style="width: 150.359px; border-width: 1px; border-color: #000; 
										padding: 0px 5px; height: 30px; border-bottom: 1px solid #000; text-align: center;
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="font-size: 16px;">安全验收评价</span>
											</div>
										</td>
										<td style="width: 150; border-width: 1px; border-color: #000; 
										padding: 0px 5px; height: 30px; border-bottom: 1px solid #000; text-align: center;
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="font-size: 16px;">1.2～1.5</span>
											</div>
										</td>
									</tr>
									<tr>
										<td style="width: 150.359px; border-width: 1px; border-color: #000; 
										padding: 0px 5px; height: 30px; border-bottom: 1px solid #000; text-align: center;
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="font-size: 16px;">安全现状评价</span>
											</div>
										</td>
										<td style="width: 150.359px; border-width: 1px; border-color: #000; 
										padding: 0px 5px; height: 30px; border-bottom: 1px solid #000; text-align: center;
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="font-size: 16px;">1.2～1.4</span>
											</div>
										</td>
									</tr>
									<tr>
										<td style="width: 150.359px; border-width: 1px; border-color: #000; 
										padding: 0px 5px; height: 30px; border-bottom: 1px solid #000; text-align: center;
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="font-size: 16px;">专项安全评价/评估</span>
											</div>
										</td>
										<td style="width: 150.359px; border-width: 1px; border-color: #000; 
										padding: 0px 5px; height: 30px; border-bottom: 1px solid #000; text-align: center;
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="font-size: 16px;">0.8～1.0</span>
											</div>
										</td>
									</tr>
									<tr>
										<td style="width: 150.359px; border-width: 1px; border-color: #000; 
										padding: 0px 5px; height: 30px; border-bottom: 1px solid #000; text-align: center;
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="font-size: 16px;">安全生产标准化</span>
											</div>
										</td>
										<td style="width: 150.359px; border-width: 1px; border-color: #000; 
										padding: 0px 5px; height: 30px; border-bottom: 1px solid #000; text-align: center;
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="font-size: 16px;">0.8～1.2</span>
											</div>
										</td>
									</tr>
									<tr>
										<td style="width: 150; border-width: 1px; border-color: #000; 
										padding: 0px 5px; height: 30px; border-bottom: 1px solid #000; text-align: center;
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="font-size: 16px;">生产安全事故应急预案</span>
											</div>
										</td>
										<td style="width: 150.359px; border-width: 1px; border-color: #000; 
										padding: 0px 5px; height: 30px; border-bottom: 1px solid #000; text-align: center;
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="font-size: 16px;">0.5～1.0</span>
											</div>
										</td>
									</tr>
									<tr>
										<td style="width: 150.359px; border-width: 1px; border-color: #000; 
										padding: 0px 5px; height: 30px; border-bottom: 1px solid #000; text-align: center;
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="font-size: 16px;">双重预防机制</span>
											</div>
										</td>
										<td style="width: 150.359px; border-width: 1px; border-color: #000; 
										padding: 0px 5px; height: 30px; border-bottom: 1px solid #000; text-align: center;
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="font-size: 16px;">0.5～1.0</span>
											</div>
										</td>
									</tr>
									<tr>
										<td style="width: 150.359px; border-width: 1px; border-color: #000; 
										padding: 0px 5px; height: 30px; border-bottom: 1px solid #000; text-align: center;
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="font-size: 16px;">可研/项目建议书</span>
											</div>
										</td>
										<td style="width: 150.359px; border-width: 1px; border-color: #000; 
										padding: 0px 5px; height: 30px; border-bottom: 1px solid #000; text-align: center;
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="font-size: 16px;">0.8～1.0</span>
											</div>
										</td>
									</tr>
									<tr>
										<td style="width: 150.359px; border-width: 1px; border-color: #000; 
										padding: 0px 5px; height: 30px; border-bottom: 1px solid #000; text-align: center;
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="font-size: 16px;">安全管家服务</span>
											</div>
										</td>
										<td style="width: 150.359px; border-width: 1px; border-color: #000; 
										padding: 0px 5px; height: 30px; border-bottom: 1px solid #000; text-align: center;
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="font-size: 16px;">1.0</span>
											</div>
										</td>
									</tr>
								</tbody>
							</table>
							<p>4.行业调整系数</p>
							<table
								style="width: 800px; height: 69px; border: 1px solid #000; border-collapse: collapse;" 
								width="470.0" 
								cellspacing="0" 
								cellpadding="0">
								<tbody>
									<tr>
										<td style="width: 150.641px; border-width: 1px; border-color: #000; 
										padding: 0px 5px; text-align: center; height: 30px; border-bottom: 1px solid #000; 
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="font-size: 16px;">行业</span>
											</div>
										</td>
										<td style="width: 150.641px; border-width: 1px; border-color: #000; 
										padding: 0px 5px; text-align: center; height: 30px; border-bottom: 1px solid #000; 
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div >
												<span style="font-size: 16px;">调整系数</span>
											</div>
										</td>
									</tr>
									
									<tr>
										<td style="width: 150.359px; border-width: 1px; border-color: #000; 
										padding: 0px 5px; height: 30px; border-bottom: 1px solid #000; text-align: center;
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="font-size: 16px;">一般工贸类</span>
											</div>
										</td>
										<td style="width: 150.359px; border-width: 1px; border-color: #000;
										padding: 0px 5px; height: 30px; border-bottom: 1px solid #000; text-align: center;
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="font-size: 16px;">1.0</span>
											</div>
										</td>
									</tr>
									<tr>
										<td style="width: 150.359px; border-width: 1px; border-color: #000; 
										padding: 0px 5px; height: 30px; border-bottom: 1px solid #000; text-align: center;
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="font-size: 16px;">金属、非金属矿及其他矿采选业</span>
											</div>
										</td>
										<td style="width: 150.359px; border-width: 1px; border-color: #000; 
										padding: 0px 5px; height: 30px; border-bottom: 1px solid #000; text-align: center;
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="font-size: 16px;">1.4</span>
											</div>
										</td>
									</tr>
									<tr>
										<td style="width: 150.359px; border-width: 1px; border-color: #000; 
										padding: 0px 5px; height: 30px; border-bottom: 1px solid #000; text-align: center;
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="font-size: 16px;">石油加工业、化学原料、化学品及医药制造业</span>
											</div>
										</td>
										<td style="width: 150; border-width: 1px; border-color: #000; 
										padding: 0px 5px; height: 30px; border-bottom: 1px solid #000; text-align: center;
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="font-size: 16px;">1.4</span>
											</div>
										</td>
									</tr>
									<tr>
										<td style="width: 150.359px; border-width: 1px; border-color: #000; 
										padding: 0px 5px; height: 30px; border-bottom: 1px solid #000; text-align: center;
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="font-size: 16px;">金属冶炼</span>
											</div>
										</td>
										<td style="width: 150.359px; border-width: 1px; border-color: #000; 
										padding: 0px 5px; height: 30px; border-bottom: 1px solid #000; text-align: center;
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="font-size: 16px;">1.4</span>
											</div>
										</td>
									</tr>
									<tr>
										<td style="width: 150.359px; border-width: 1px; border-color: #000; 
										padding: 0px 5px; height: 30px; border-bottom: 1px solid #000; text-align: center;
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="font-size: 16px;">房屋和土木工程建筑业</span>
											</div>
										</td>
										<td style="width: 150.359px; border-width: 1px; border-color: #000; 
										padding: 0px 5px; height: 30px; border-bottom: 1px solid #000; text-align: center;
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="font-size: 16px;">1.2</span>
											</div>
										</td>
									</tr>
									<tr>
										<td style="width: 150.359px; border-width: 1px; border-color: #000; 
										padding: 0px 5px; height: 30px; border-bottom: 1px solid #000; text-align: center;
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="font-size: 16px;">仓储业</span>
											</div>
										</td>
										<td style="width: 150.359px; border-width: 1px; border-color: #000; 
										padding: 0px 5px; height: 30px; border-bottom: 1px solid #000; text-align: center;
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="font-size: 16px;">1.0</span>
											</div>
										</td>
									</tr>
									<tr>
										<td style="width: 150; border-width: 1px; border-color: #000; 
										padding: 0px 5px; height: 30px; border-bottom: 1px solid #000; text-align: center;
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="font-size: 16px;">水利、水电工程业</span>
											</div>
										</td>
										<td style="width: 150.359px; border-width: 1px; border-color: #000; 
										padding: 0px 5px; height: 30px; border-bottom: 1px solid #000; text-align: center;
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="font-size: 16px;">1.3</span>
											</div>
										</td>
									</tr>
									<tr>
										<td style="width: 150.359px; border-width: 1px; border-color: #000; 
										padding: 0px 5px; height: 30px; border-bottom: 1px solid #000; text-align: center;
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="font-size: 16px;">火力发电业、热力生产和供应业</span>
											</div>
										</td>
										<td style="width: 150.359px; border-width: 1px; border-color: #000; 
										padding: 0px 5px; height: 30px; border-bottom: 1px solid #000; text-align: center;
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="font-size: 16px;">1.2</span>
											</div>
										</td>
									</tr>
									<tr>
										<td style="width: 150.359px; border-width: 1px; border-color: #000; 
										padding: 0px 5px; height: 30px; border-bottom: 1px solid #000; text-align: center;
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="font-size: 16px;">风力发电业、太阳能发电业、再生能源发电业</span>
											</div>
										</td>
										<td style="width: 150.359px; border-width: 1px; border-color: #000; 
										padding: 0px 5px; height: 30px; border-bottom: 1px solid #000; text-align: center;
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="font-size: 16px;">1.0</span>
											</div>
										</td>
									</tr>
									<tr>
										<td style="width: 150.359px; border-width: 1px; border-color: #000; 
										padding: 0px 5px; height: 30px; border-bottom: 1px solid #000; text-align: center;
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="font-size: 16px;">金属制品业、非金属矿物制品业</span>
											</div>
										</td>
										<td style="width: 150.359px; border-width: 1px; border-color: #000; 
										padding: 0px 5px; height: 30px; border-bottom: 1px solid #000; text-align: center;
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="font-size: 16px;">1.2</span>
											</div>
										</td>
									</tr>
									<tr>
										<td style="width: 150.359px; border-width: 1px; border-color: #000; 
										padding: 0px 5px; height: 30px; border-bottom: 1px solid #000; text-align: center;
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="font-size: 16px;">城市轨道交通及辅助设施</span>
											</div>
										</td>
										<td style="width: 150.359px; border-width: 1px; border-color: #000; 
										padding: 0px 5px; height: 30px; border-bottom: 1px solid #000; text-align: center;
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="font-size: 16px;">1.2</span>
											</div>
										</td>
									</tr>
									<tr>
										<td style="width: 150.359px; border-width: 1px; border-color: #000; 
										padding: 0px 5px; height: 30px; border-bottom: 1px solid #000; text-align: center;
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="font-size: 16px;">公路、港口码头</span>
											</div>
										</td>
										<td style="width: 150.359px; border-width: 1px; border-color: #000; 
										padding: 0px 5px; height: 30px; border-bottom: 1px solid #000; text-align: center;
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="font-size: 16px;">1.2</span>
											</div>
										</td>
									</tr>
								</tbody>
							</table>
							<p>5.工程复杂程度调整系数</p>
							<p>工程复杂程度，可根据项目风险评估确定。</p>
							<table
								style="width: 800px; height: 69px; border: 1px solid #000; border-collapse: collapse;" 
								width="470.0" 
								cellspacing="0" 
								cellpadding="0">
								<tbody>
									<tr>
										<td style="width: 150.641px; border-width: 1px; border-color: #000; 
										padding: 0px 5px; text-align: center; height: 30px; border-bottom: 1px solid #000; 
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="font-size: 16px;">工程复杂程度</span>
											</div>
										</td>
										<td style="width: 150.641px; border-width: 1px; border-color: #000; 
										padding: 0px 5px; text-align: center; height: 30px; border-bottom: 1px solid #000; 
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div >
												<span style="font-size: 16px;">调整系数</span>
											</div>
										</td>
									</tr>
									
									<tr>
										<td style="width: 150.359px; border-width: 1px; border-color: #000; 
										padding: 0px 5px; height: 30px; border-bottom: 1px solid #000; text-align: center;
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="font-size: 16px;">简单，风险程度一般</span>
											</div>
										</td>
										<td style="width: 150.359px; border-width: 1px; border-color: #000;
										padding: 0px 5px; height: 30px; border-bottom: 1px solid #000; text-align: center;
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="font-size: 16px;">0.8</span>
											</div>
										</td>
									</tr>
									<tr>
										<td style="width: 150.359px; border-width: 1px; border-color: #000; 
										padding: 0px 5px; height: 30px; border-bottom: 1px solid #000; text-align: center;
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="font-size: 16px;">中等，风险程度中等</span>
											</div>
										</td>
										<td style="width: 150.359px; border-width: 1px; border-color: #000; 
										padding: 0px 5px; height: 30px; border-bottom: 1px solid #000; text-align: center;
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="font-size: 16px;">1.0</span>
											</div>
										</td>
									</tr>
									<tr>
										<td style="width: 150.359px; border-width: 1px; border-color: #000; 
										padding: 0px 5px; height: 30px; border-bottom: 1px solid #000; text-align: center;
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="font-size: 16px;">复杂，风险程度高</span>
											</div>
										</td>
										<td style="width: 150; border-width: 1px; border-color: #000; 
										padding: 0px 5px; height: 30px; border-bottom: 1px solid #000; text-align: center;
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="font-size: 16px;">1.2</span>
											</div>
										</td>
									</tr>
									<tr>
										<td style="width: 150.359px; border-width: 1px; border-color: #000; 
										padding: 0px 5px; height: 30px; border-bottom: 1px solid #000; text-align: center;
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="font-size: 16px;">非常复杂，风险程度非常高</span>
											</div>
										</td>
										<td style="width: 150.359px; border-width: 1px; border-color: #000; 
										padding: 0px 5px; height: 30px; border-bottom: 1px solid #000; text-align: center;
										border-right: #000; border: 1px solid #000; vertical-align: middle;" valign="top">
											<div>
												<span style="font-size: 16px;">1.4</span>
											</div>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 底部 -->
		<footer2></footer2>
	</div>
</template>

<script>
import daohang from "../components/nav.vue";
import footer2 from "../components/footer.vue";
export default {
	name: "App",
	data() {
		return {
			h1: "集团概况",
			P1: "欢迎加入我们",
			Img1: require("../assets/img/bannerjituan.png"),
		};
	},
	components: {
		daohang,
		footer2,
	},
	created() {
		this.$nextTick(() => {
			this.toLocal();
		});
	},
	mounted() {
		let _this = this;
		_this.$nextTick(function () {
			window.addEventListener("scroll", _this.handleScroll);
		});
	},
	methods: {
		toLocal() {
			//查找存储的锚点id
			let Id = localStorage.getItem("toId");
			let toElement = document.getElementById(Id);
			//锚点存在跳转
			if (Id) {
				toElement.scrollIntoView();
				localStorage.setItem("toId", "");
			}
		},
	},
};
</script>

<style scoped>
p {
	font-size: 16px;
	line-height: 40px;
}
.GroupOverview {
	width: 100vw;
	margin: 0 auto;
}
.about {
	height: 2500px;
	position: relative;
	margin: 0px 200px;
}

.about-bg {
	background-image: url("../assets/img/蒙版.png");
}

.about-top {
	position: relative;
	display: flex;
}

.about-top-l {
	position: absolute;
	left: 20px;
	top: 85px;
	width: 100%;
	height: 442px;
}

.about-top-l > span {
	font-size: 32px;
}

.about-top-l > p {
	font-size: 16px;
	line-height: 40px;
}

.img-yinying {
	position: absolute;
	top: 58px;
	left: -17px;
	width: 17px;
	height: 408px;
	background: #f8f8f8;
}

.about-top-r {
	position: absolute;
	right: -110px;
}

.about-top-r img {
	width: 487px;
	height: 517px;
}

.about-down {
	position: absolute;
	bottom: 20px;
}

.item {
	top: 236px;
	position: relative;
}

.item2 {
	top: 158px;
	left: 328px;
	position: relative;
}

.item3 {
	top: 79px;
	left: 645px;
	position: relative;
}

.item4 {
	left: 1049px;
	position: relative;
}

.item-1 {
	color: rgba(35, 51, 127, 1);
	font-size: 60px;
}

.item-2 {
	position: absolute;
	color: rgba(35, 51, 127, 1);
	font-size: 16.8px;
}

.item-3 {
	position: absolute;
	left: 88px;
	top: 41px;
	color: rgba(102, 102, 102, 1);
	font-size: 20px;
	width: 100px;
}

.item2-3 {
	position: relative;
	color: rgba(102, 102, 102, 1);
	left: 18px;
	font-size: 20px;
}

.item3-3 {
	position: relative;
	color: rgba(102, 102, 102, 1);
	left: 18px;
	font-size: 20px;
}

.item4-3 {
	position: relative;
	color: rgba(102, 102, 102, 1);
	left: 18px;
	font-size: 20px;
}

.qywh {
	width: 100%;
	height: 598px;
	background-color: rgba(248, 248, 248, 1);
}

.qywh h1 {
	color: rgba(68, 68, 68, 1);
	font-size: 32px;
	line-height: 134px;
	text-align: center;
}

.qywh-item {
	margin: 30px 200px;

	justify-content: space-between;
	display: flex;
}

.qywh-item1 {
	margin-right: 16px;
	position: relative;
	width: 288px;
	height: 348px;
	background-color: rgba(255, 255, 255, 1);
	box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
	border-radius: 10px;
}

.qywh-item1 span {
	position: absolute;
	left: 91px;
	top: 30px;
	display: inline-block;
	border-radius: 100%;
	width: 90px;
	height: 90px;
	background-color: rgba(52, 145, 229, 1);
}

.qywh-item1 span > img {
	border-radius: 100%;
	height: 100%;
	width: 100%;
}

.qywh-text {
	position: absolute;
	top: 138px;
	left: 91px;
	width: 100px;
	color: rgba(68, 68, 68, 1);
	font-size: 24px;
}

.qywh-text2 {
	position: absolute;
	top: 192px;
	left: 10px;
	text-align: center;
	color: rgba(68, 68, 68, 1);
	font-size: 18px;
	line-height: 28px;
}

.text2-2 {
	width: 91px;
	line-height: 30px;
	position: absolute;
	left: 92px;
}

/* 总裁寄语 */
.President {
	width: 100%px;
	height: 801px;
	background-image: url(../assets/img/摄图网_500941399_banner_企业文化背景（非企业商用）.png);
	background-size: 100% 801px;
	padding-top: 84px;
}

.President-title > p {
	font-size: 32px;
	font-family: PingFangSC-Semibold, PingFang SC;
	font-weight: 600;
	color: #ffffff;
	line-height: 45px;
	text-align: center;
}

.President-text {
	width: 1200px;
	height: 465px;
	margin: 55px auto;
	background: #ffffff;
	box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.3);
	border-radius: 10px;
}

.President-text > p {
	width: 1000px;
	height: 262px;
	font-size: 16px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #666666;
	line-height: 40px;
	margin: 0 auto;
	padding-top: 71px;
}

.President-text2 {
	height: 50px;
	font-size: 24px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #222222;
	line-height: 33px;
	text-align: center;
	margin-top: 42px;
	position: relative;
}
.President-text2 > span {
	position: absolute;
	left: 491px;
	top: 15px;
}
.President-text2 > img {
	position: absolute;
}
/* 发展历程 */
.DevelopmentHistory {
	width: 1920;
	height: 527px;
	background-image: url("../assets/img/fazhan_bg.png");
}
.DevelopmentHistory_cont {
	width: 1200px;
	margin: 0 auto;
}
.DevelopmentHistory_cont_img img {
	margin-top: 61px;
	width: 32px;
	height: 32px;
}
.DevelopmentHistory_cont_img1 {
	float: left;
}
.DevelopmentHistory_cont_img2 {
	float: right;
}
.DevelopmentHistory_cont > h1 {
	text-align: center;
	margin-top: 64px;

	font-size: 32px;
	font-family: PingFangSC-Semibold, PingFang SC;
	font-weight: 600;
	color: #444444;
	line-height: 45px;
}
.DevelopmentHistory_cont_ul {
	display: flex;
	margin-top: 12px;
	margin-left: 59px;
	justify-content: space-around;
}
.DevelopmentHistory_cont_ul ul p {
	font-size: 24px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	color: #23337f;
	line-height: 33px;
}

.DevelopmentHistory_cont_ul li {
	margin-top: 8px;
	list-style-type: none;

	font-size: 14px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #444444;
	line-height: 24px;
}
</style>
