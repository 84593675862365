<template>
	<div class="GroupOverview">
		<daohang></daohang>
		<!-- 服务方案 -->
		<div class="about-bg">
			<div class="about" id="about">
				<div class="about-top">
					<div class="about-top-l">
						<span style="font-weight: bolder;">2.安全现状评价/评估、安全风险评估</span>
						<p>
							<strong style="font-weight: bolder;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;法规依据：</strong>
						</p>
						<p>
							&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（1）《危险化学品安全管理条例》中华人民共和国国务院令第591号（根据国务院令第645号令修改）第二十二条，生产、储存危险化学品的企业，应当委托具备国家规定的资质条件的机构，对本企业的安全生产条件每3年进行一次安全评价，提出安全评价报告
						</p>
						<p>
							&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（2）《安徽省工贸行业安全监管工作警示单》 （2023年第1号）的规定“对已经建成投入生产的大中型企业和金属冶炼企业的项目，存在设计不规范或设计与现状严重不符的，要督促企业开展安全设计诊断，没有设计的要补做设计，从本质安全上防控风险”和“对已建成的非高危小微企业建设项目，没有设计的或设计不规范的，要督促开展安全现状评估，提出安全防范措施”的要求。
						</p>
						<p>
							&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（3）皖应急函〔2022〕205号 安徽省工贸行业安全监管工作警示单（2022年第2号）、督促、指导 2016-2020年未按规定开展安全设施“三同时”的大、中型企业补做安全现状评价;督促、指导 2016年以来所有事故企业(未开展安全设施“三同时”或经核查不符合规定的)补做安全现状评价。						
						</p>
						<div>
							<p>
								<span style="font-size: 18px;line-height: 40px;">
									<strong style="font-weight: bolder;">①安全现状评价/评估</strong>
								</span>
							</p>
							<p>
								<span style="font-size: 16px;line-height: 40px;">
									<strong>定义</strong>：安全现状评价是在系统生命周期内的生产运行期，针对系统、工程的（某一个生产经营单位总体或局部的生产经营活动的）安全现状进行的安全评价，通过评价查找其存在的危险、有害因素，确定其程度，提出合理可行的安全对策措施及建议。
								</span>
							</p>
							<p>
								<span style="font-size: 16px;line-height: 40px;">实施阶段：运行过程中、投产运行满三年</span>
							</p>
							<p>
								<span style="font-size: 16px;line-height: 40px;">评价周期：35个工作日</span>
							</p>
							<p>
								<span style="font-size: 16px;line-height: 40px;">所需基本资料：表2-1</span>
							</p>
							<p style="position: relative;left: 35%;">
								<span style="font-size: 18px;line-height: 40px;">
									<strong style="font-weight: bolder;">表2-1 安全现状评价/评估所需基本资料</strong>
								</span>
							</p>
							<table 
								style="width: 800px; height: 69px; border: 1px solid #000;margin: 0 auto; border-collapse: collapse;" 
								width="470.0" 
								cellspacing="0" 
								cellpadding="0">
								<tbody>
									<tr style="height: 20px;">
										<td style="width: 105.359px; border-width: 1px; border-color: #000; 
										padding: 0px 5px; height: 20px; text-align: center; border-bottom: 1px solid #000; 
										border-right: #000; border: 1px solid #000;" valign="top">
											<div>
												<span style="font-size: 16px;">序号</span>
											</div>
										</td>
										<td style="width: 730.641px; border-width: 1px; border-color: #000; 
										padding: 0px 5px; text-align: center; height: 20px; border-bottom: 1px solid #000; 
										border-right: #000; border: 1px solid #000;" valign="top">
											<div >
												<span style="font-size: 16px;">必要资料</span>
											</div>
										</td>
									</tr>
									<tr style="text-align: center;">
										<td style="width: 105.359px; border-width: 1px; border-color: #000; 
										padding: 0px 5px; height: 20px; border-bottom: 1px solid #000; 
										border-right: #000; border: 1px solid #000;" valign="top">
											<div>
												<span style="font-size: 16px;">1</span>
											</div>
										</td>
										<td style="width: 730.641px; border-width: 1px; border-color: #000; 
										padding: 0px 5px; height: 20px; border-bottom: 1px solid #000; 
										border-right: #000; border: 1px solid #000;" valign="top">
											<div>
												<span style="font-size: 16px; float: left;">工程竣工验收报告、消防验收文件、建构筑物的防雷验收检测报告</span>
											</div>
										</td>
									</tr>
									<tr style="text-align: center;">
										<td style="width: 105.359px; border-width: 1px; border-color: #000; 
										padding: 0px 5px; height: 20px; border-bottom: 1px solid #000; 
										border-right: #000; border: 1px solid #000;" valign="top">
											<div>
												<span style="font-size: 16px;">2</span>
											</div>
										</td>
										<td style="width: 730.641px; border-width: 1px; border-color: #000; 
										padding: 0px 5px; height: 20px; border-bottom: 1px solid #000; 
										border-right: #000; border: 1px solid #000;" valign="top">
											<div>
												<span style="ont-size: 16px; float: left;">生产工艺、设备设施清单、原辅材料清单、化学品清单</span>
											</div>
										</td>
									</tr>
									<tr style="text-align: center;">
										<td style="width: 105.359px; border-width: 1px; border-color: #000; 
										padding: 0px 5px; height: 20px; border-bottom: 1px solid #000; 
										border-right: #000; border: 1px solid #000;" valign="top">
											<div>
												<span style="font-kerning: none; font-size: 16px;">3</span>
											</div>
										</td>
										<td style="width: 730.641px; border-width: 1x; border-color: #000; 
										padding: 0px 5px; height: 20px; border-bottom: 1px solid #000; 
										border-right: #000; border: 1px solid #000;" valign="top">
											<div>
												<span style="ffont-size: 16px; float: left;">主要负责人和安全管理人员合格证 、特种作业人员及特种设备作业人员合法性证件、特种设备、安全附件等合法性检测报告</span>
											</div>
										</td>
									</tr>
									<tr style="text-align: center;">
										<td style="width: 105.359px; border-width: 1px; border-color: #000; 
										padding: 0px 5px; height: 20px; border-bottom: 1px solid #000; 
										border-right: #000; border: 1px solid #000;" valign="top">
											<div>
												<span style="font-kerning: none; font-size: 16px;">4</span>
											</div>
										</td>
										<td style="width: 730.641px; border-width: 1x; border-color: #000; 
										padding: 0px 5px; height: 20px; border-bottom: 1px solid #000; 
										border-right: #000; border: 1px solid #000;" valign="top">
											<div>
												<span style="ffont-size: 16px; float: left;">安全管理资料文件（制度、操作规程、教育培训、隐患排查、应急救援、相关方管理）</span>
											</div>
										</td>
									</tr>
									<tr style="text-align: center;">
										<td style="width: 105.359px; border-width: 1px; border-color: #000; 
										padding: 0px 5px; height: 20px; border-bottom: 1px solid #000; 
										border-right: #000; border: 1px solid #000;" valign="top">
											<div>
												<span style="font-kerning: none; font-size: 16px;">5</span>
											</div>
										</td>
										<td style="width: 730.641px; border-width: 1x; border-color: #000; 
										padding: 0px 5px; height: 20px; border-bottom: 1px solid #000; 
										border-right: #000; border: 1px solid #000;" valign="top">
											<div>
												<span style="ffont-size: 16px; float: left;">工伤保险缴纳证明、应急预案备案表等</span>
											</div>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
						<div>
							<p>
								<span style="font-size: 18px;line-height: 40px;">
									<strong style="font-weight: bolder;">②安全风险评估</strong>
								</span>
							<p>
								<span style="font-size: 16px;line-height: 40px;">
									<strong>定义</strong>：对一个生产经营单位某装置、区域或一个工业园区的整体的评估，也适用于某一特定的生产方式、生产工艺、生产装置或作业场所的专项评估。辨识与分析其存在的危险、有害因素，审查相关法律法规、规章、标准，规范要求的符合性，预测发生事故的可能性及其风险程度，提出科学、合理、可行的安全对策措施建议。做出评估结论。例如：某加药系统安全现状评估、某化工园区整体性安全风险评估。
								</span>
							</p>
							<p>
								<span style="font-size: 16px;line-height: 40px;">实施阶段：投产运行阶段</span>
							</p>
							<p>
								<span style="font-size: 16px;line-height: 40px;">评价周期：35个工作日</span>
							</p>
							<p>
								<span style="font-size: 16px;line-height: 40px;">所需基本资料：表2-2</span>
							</p>
							<p style="position: relative;left: 40%;">
								<span style="font-size: 18px;line-height: 40px;">
									<strong style="font-weight: bolder;">表1-2 安全设施设计所需基本资料</strong>
								</span>
							</p>
							<table
								style="width: 800px; height: 69px; border: 1px solid #000;margin: 0 auto; border-collapse: collapse;" 
								width="470.0" 
								cellspacing="0" 
								cellpadding="0">
								<tbody>
									<tr style="height: 20px;">
										<td style="width: 105.359px; border-width: 1px; border-color: #000; 
										padding: 0px 5px; height: 20px; text-align: center; border-bottom: 1px solid #000; 
										border-right: #000; border: 1px solid #000;" valign="top">
											<div>
												<span style="font-size: 16px;">序号</span>
											</div>
										</td>
										<td style="width: 730.641px; border-width: 1px; border-color: #000; 
										padding: 0px 5px; text-align: center; height: 20px; border-bottom: 1px solid #000; 
										border-right: #000; border: 1px solid #000;" valign="top">
											<div >
												<span style="font-size: 16px;">必要资料</span>
											</div>
										</td>
									</tr>
									<tr style="text-align: center;">
										<td style="width: 105.359px; border-width: 1px; border-color: #000; 
										padding: 0px 5px; height: 20px; border-bottom: 1px solid #000; 
										border-right: #000; border: 1px solid #000;" valign="top">
											<div>
												<span style="font-size: 16px;">1</span>
											</div>
										</td>
										<td style="width: 730.641px; border-width: 1px; border-color: #000; 
										padding: 0px 5px; height: 20px; border-bottom: 1px solid #000; 
										border-right: #000; border: 1px solid #000;" valign="top">
											<div>
												<span style="font-size: 16px; float: left;"><strong>某装置、区域布置图</strong></span>
											</div>
										</td>
									</tr>
									<tr style="text-align: center;">
										<td style="width: 105.359px; border-width: 1px; border-color: #000; 
										padding: 0px 5px; height: 20px; border-bottom: 1px solid #000; 
										border-right: #000; border: 1px solid #000;" valign="top">
											<div>
												<span style="font-size: 16px;">2</span>
											</div>
										</td>
										<td style="width: 730.641px; border-width: 1px; border-color: #000; 
										padding: 0px 5px; height: 20px; border-bottom: 1px solid #000; 
										border-right: #000; border: 1px solid #000;" valign="top">
											<div>
												<span style="ont-size: 16px; float: left;">生产工艺、设备设施清单、原辅材料清单、化学品清单</span>
											</div>
										</td>
									</tr>
									<tr style="text-align: center;">
										<td style="width: 105.359px; border-width: 1px; border-color: #000; 
										padding: 0px 5px; height: 20px; border-bottom: 1px solid #000; 
										border-right: #000; border: 1px solid #000;" valign="top">
											<div>
												<span style="font-kerning: none; font-size: 16px;">3</span>
											</div>
										</td>
										<td style="width: 730.641px; border-width: 1x; border-color: #000; 
										padding: 0px 5px; height: 20px; border-bottom: 1px solid #000 
										border-right: #000; border: 1px solid #000;" valign="top">
											<div>
												<span style="ffont-size: 16px; float: left;">合法性证明文件</span>
											</div>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 底部 -->
		<footer2></footer2>
	</div>
</template>

<script>
import daohang from "../components/nav.vue";
import footer2 from "../components/footer.vue";
export default {
	name: "App",
	data() {
		return {
			h1: "集团概况",
			P1: "欢迎加入我们",
			Img1: require("../assets/img/bannerjituan.png"),
		};
	},
	components: {
		daohang,
		footer2,
	},
	created() {
		this.$nextTick(() => {
			this.toLocal();
		});
	},
	mounted() {
		let _this = this;
		_this.$nextTick(function () {
			window.addEventListener("scroll", _this.handleScroll);
		});
	},
	methods: {
		toLocal() {
			//查找存储的锚点id
			let Id = localStorage.getItem("toId");
			let toElement = document.getElementById(Id);
			//锚点存在跳转
			if (Id) {
				toElement.scrollIntoView();
				localStorage.setItem("toId", "");
			}
		},
	},
};
</script>

<style scoped>
.GroupOverview {
	width: 100vw;
	margin: 0 auto;
}
.about {
	height: 1300px;
	position: relative;
	margin: 0px 200px;
}

.about-bg {
	background-image: url("../assets/img/蒙版.png");
}

.about-top {
	position: relative;
	display: flex;
}

.about-top-l {
	position: absolute;
	left: 20px;
	top: 85px;
	width: 100%;
	height: 442px;
}

.about-top-l > span {
	font-size: 32px;
}

.about-top-l > p {
	font-size: 16px;
	line-height: 40px;
}

.img-yinying {
	position: absolute;
	top: 58px;
	left: -17px;
	width: 17px;
	height: 408px;
	background: #f8f8f8;
}

.about-top-r {
	position: absolute;
	right: -110px;
}

.about-top-r img {
	width: 487px;
	height: 517px;
}

.about-down {
	position: absolute;
	bottom: 20px;
}

.item {
	top: 236px;
	position: relative;
}

.item2 {
	top: 158px;
	left: 328px;
	position: relative;
}

.item3 {
	top: 79px;
	left: 645px;
	position: relative;
}

.item4 {
	left: 1049px;
	position: relative;
}

.item-1 {
	color: rgba(35, 51, 127, 1);
	font-size: 60px;
}

.item-2 {
	position: absolute;
	color: rgba(35, 51, 127, 1);
	font-size: 16.8px;
}

.item-3 {
	position: absolute;
	left: 88px;
	top: 41px;
	color: rgba(102, 102, 102, 1);
	font-size: 20px;
	width: 100px;
}

.item2-3 {
	position: relative;
	color: rgba(102, 102, 102, 1);
	left: 18px;
	font-size: 20px;
}

.item3-3 {
	position: relative;
	color: rgba(102, 102, 102, 1);
	left: 18px;
	font-size: 20px;
}

.item4-3 {
	position: relative;
	color: rgba(102, 102, 102, 1);
	left: 18px;
	font-size: 20px;
}

.qywh {
	width: 100%;
	height: 598px;
	background-color: rgba(248, 248, 248, 1);
}

.qywh h1 {
	color: rgba(68, 68, 68, 1);
	font-size: 32px;
	line-height: 134px;
	text-align: center;
}

.qywh-item {
	margin: 30px 200px;

	justify-content: space-between;
	display: flex;
}

.qywh-item1 {
	margin-right: 16px;
	position: relative;
	width: 288px;
	height: 348px;
	background-color: rgba(255, 255, 255, 1);
	box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
	border-radius: 10px;
}

.qywh-item1 span {
	position: absolute;
	left: 91px;
	top: 30px;
	display: inline-block;
	border-radius: 100%;
	width: 90px;
	height: 90px;
	background-color: rgba(52, 145, 229, 1);
}

.qywh-item1 span > img {
	border-radius: 100%;
	height: 100%;
	width: 100%;
}

.qywh-text {
	position: absolute;
	top: 138px;
	left: 91px;
	width: 100px;
	color: rgba(68, 68, 68, 1);
	font-size: 24px;
}

.qywh-text2 {
	position: absolute;
	top: 192px;
	left: 10px;
	text-align: center;
	color: rgba(68, 68, 68, 1);
	font-size: 18px;
	line-height: 28px;
}

.text2-2 {
	width: 91px;
	line-height: 30px;
	position: absolute;
	left: 92px;
}

/* 总裁寄语 */
.President {
	width: 100%px;
	height: 801px;
	background-image: url(../assets/img/摄图网_500941399_banner_企业文化背景（非企业商用）.png);
	background-size: 100% 801px;
	padding-top: 84px;
}

.President-title > p {
	font-size: 32px;
	font-family: PingFangSC-Semibold, PingFang SC;
	font-weight: 600;
	color: #ffffff;
	line-height: 45px;
	text-align: center;
}

.President-text {
	width: 1200px;
	height: 465px;
	margin: 55px auto;
	background: #ffffff;
	box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.3);
	border-radius: 10px;
}

.President-text > p {
	width: 1000px;
	height: 262px;
	font-size: 16px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #666666;
	line-height: 40px;
	margin: 0 auto;
	padding-top: 71px;
}

.President-text2 {
	height: 50px;
	font-size: 24px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #222222;
	line-height: 33px;
	text-align: center;
	margin-top: 42px;
	position: relative;
}
.President-text2 > span {
	position: absolute;
	left: 491px;
	top: 15px;
}
.President-text2 > img {
	position: absolute;
}
/* 发展历程 */
.DevelopmentHistory {
	width: 1920;
	height: 527px;
	background-image: url("../assets/img/fazhan_bg.png");
}
.DevelopmentHistory_cont {
	width: 1200px;
	margin: 0 auto;
}
.DevelopmentHistory_cont_img img {
	margin-top: 61px;
	width: 32px;
	height: 32px;
}
.DevelopmentHistory_cont_img1 {
	float: left;
}
.DevelopmentHistory_cont_img2 {
	float: right;
}
.DevelopmentHistory_cont > h1 {
	text-align: center;
	margin-top: 64px;

	font-size: 32px;
	font-family: PingFangSC-Semibold, PingFang SC;
	font-weight: 600;
	color: #444444;
	line-height: 45px;
}
.DevelopmentHistory_cont_ul {
	display: flex;
	margin-top: 12px;
	margin-left: 59px;
	justify-content: space-around;
}
.DevelopmentHistory_cont_ul ul p {
	font-size: 24px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	color: #23337f;
	line-height: 33px;
}

.DevelopmentHistory_cont_ul li {
	margin-top: 8px;
	list-style-type: none;

	font-size: 14px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #444444;
	line-height: 24px;
}
</style>
